<template>
    <div class="form-input options options-select" :class="[classString, {'error': error}, {'locked': element.locked === true, 'unlocked' : element.locked === false}]"> <!-- locked or nothing -->
        <span class="padlock-wrapper"><label :for="element.name"><Label :label="labelText" :tooltip="element.tooltip" /></label><a href="#" title="" class="options-toggle" @click="toggleIgnoreOptions($event)"><span v-if="!ignoreOptions"><span class="mobile-text">{{text.switch_to_text_mobile}}</span><span class="desktop-text">{{text.switch_to_text}}</span></span><span v-else><span class="mobile-text">{{text.switch_to_options_mobile}}</span><span class="desktop-text">{{text.switch_to_options}}</span></span></a><Lock :locked="element.locked" @toggleLocked="toggleLockedState($event)" /></span>

        <div class="options-accordion" :class="[{'open' : showOptions}, {'closed' : showOptions === false}]" v-if="!ignoreOptions">
            <a class="options-accordion-heading" @click="toggleAccordion($event);"><span>{{text.content_options}}</span><span class="options-accordion-toggle-text options-accordion-toggle-text-show">{{text.show}}</span><span class="options-accordion-toggle-text options-accordion-toggle-text-hide">{{text.hide}}</span></a>
            <ul class="options-accordion-list" v-if="element.options.length > 0">
                <li class="options-item" v-for="row in element.options" :key="row.id">
                    <label :for="'selectoption' + row.id" class="form-checkbox">
                        <input autocomplete="off" type="checkbox" name="selectoption" :id="'selectoption' + row.id" :value="row.id" v-model="selectedOptions" @focus="onInputFocusSelectedOptions" @blur="onInputBlurSelectedOptions" /><span></span> <span v-if="row.name"><strong>{{row.name}}</strong><br/>{{row.value}}</span><span v-else>{{row.value}}</span>
                    </label>
                </li>
            </ul>
            <ul class="options-accordion-list" v-else>
                <li class="options-item">{{text.no_options_available}}</li>
            </ul>
        </div>

        <input autocomplete="off" type="text" :id="element.name" :placeholder="element.placeholder" v-model="inputValue" :disabled="element.disabled" :readonly="element.readonly" @focus="onInputFocusStandard" @blur="onInputBlurStandard" v-else >
        <span class="error-message" v-if="error">{{error}}</span>
    </div>
</template>

<script>
    import lang from '@/shared/lang';

    import Label from "@/components/form/label";
    import Lock from "@/components/form/lock";

    import {formElementMixin} from "@/components/mixins/formelement";
    import {formElementPickerMixin} from "@/components/mixins/formelement_picker";

    export default {
        name: "Formelement_textpicker_edit",
        components: {Label, Lock},
        mixins: [formElementMixin, formElementPickerMixin],
        props: {
            element: {
                type: Object
            },
            value: {
                type: [String, Array]
            },
            classString: {
                type: String
            }
        },
        computed: {
            text() {
                return lang;
            },
            inputValue: {
                get() { return this.value },
                set(inputValue) {this.$emit('input', inputValue)}
            },
        },
        methods: {
            clear() {
                document.getElementById(this.element.name).value = "";
            }
        },
    }
</script>