<template>
    <div class="form-input options options-select mediapicker-value" :class="[classString, {'error': error}, {'locked': element.locked === true, 'unlocked' : element.locked === false}]"> <!-- locked or nothing -->
        <label :for="element.name" class="lock"><Label :label="labelText" :tooltip="element.tooltip" /></label>

        <ul class="options-accordion-image-list viewing" v-if="mediaItemShortList.length > 0">
            <li class="options-image-list-item" v-for="row in mediaItemShortList" :key="row.id">
                <span class="image image-border"><img v-auth-src="mediaBasePath + row.thumbnailFilePath" :alt="row.name | cleanString" /></span>
                <p class="image-title">{{row.name}}</p>
            </li>
        </ul>
        <p class="pt-4 pb-4" v-else>{{text.no_results}}</p>

        <span class="error-message" v-if="error">{{error}}</span>
    </div>
</template>

<script>
    import lang from '@/shared/lang';

    import Label from "@/components/form/label";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {formElementMixin} from "@/components/mixins/formelement";
    import {formElementPickerMixin} from "@/components/mixins/formelement_picker";
    import {formElementMediaPickerMixin} from "@/components/mixins/formelement_mediapicker";

    export default {
        name: "Formelement_mediapicker_value",
        components: {Label},
        mixins: [formatContentMixin, formElementMixin, formElementPickerMixin, formElementMediaPickerMixin],
        props: {
            element: {
                type: Object
            },
            value: {
                type: [Number, String, Array]
            },
            classString: {
                type: String
            }
        },
        computed: {
            text() {
                return lang;
            },
            // Overwriting method in formElementPickerMixin
            labelText() {
                return this.element.label ? this.element.label : "";
            }
        },
        async mounted() {
          if(this.element.value){
            await this.loadMediaItemsByID([this.element.value]  );
          }
        }
    }
</script>