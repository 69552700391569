<template>
    <div class="form-input options options-select mediapicker-select" :class="[classString, {'error': error}, {'locked': element.locked === true, 'unlocked' : element.locked === false}]"> <!-- locked or nothing -->
        <label :for="element.name" class="lock"><Label :label="element.label" :tooltip="element.tooltip" /></label>

        <div v-if="pickerEnabled">
            <ul :id="element.name" class="options-accordion-image-list viewing" v-if="mediaItemShortList.length > 0">
                <li class="options-image-list-item" v-for="row in mediaItemShortList" :key="row.id">
                    <label :for="element.name + '-chooseimage' + row.id" class="form-checkbox image image-border"><input type="radio" :id="element.name + '-chooseimage' + row.id" :value="row.id" v-model="selectedOption" @focus="onInputFocusSelectedOption" @blur="onInputBlurSelectedOption" /><span></span><img v-auth-src="mediaBasePath + row.thumbnailFilePath" :alt="row.name | cleanString" /></label>
                    <p class="image-title">{{row.name}}</p>
                </li>
            </ul>
            <p class="pt-4 pb-4" v-else>{{text.no_image_results_view}}</p>

            <div v-if="!element.locked">
                <div class="options-accordion open" v-if="showExtraImages">
                    <span class="options-accordion-heading"><span>{{text.select_image}}</span></span>
                    <div class="options-accordion-content">
                        <div class="options-library-select">
                            <label :for="element.name + '-mediaGroupSelect'">{{text.select_media_group}}</label>
                            <div class="form-select">
                                <select autocomplete="off" :id="element.name + '-mediaGroupSelect'" v-model="selectedMediaGroupID">
                                    <option value="0" disabled>- {{text.select_option}} -</option>
                                    <option v-for="(option, index) in mediaGroupSelectOptions" :key="index" :value="option.value">{{option.text}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="options-accordion-image" v-if="selectedMediaGroupID > 0">
                            <div class="options-accordion-image-meta" v-if="mediaItemList.length > 0">
                                <span class="images-show">show <a href="#" :title="text.show + ' 10'" class="images-number-select" :class="[{'active' : mediaItemListNumberPerPage === 10}]" @click="onNumberPerPageChange($event, 10);">10</a> <a href="#" :title="text.show + ' 15'" class="images-number-select" :class="[{'active' : mediaItemListNumberPerPage === 15}]" @click="onNumberPerPageChange($event, 15);">15</a> <a href="#" :title="text.show + ' 50'" class="images-number-select" :class="[{'active' : mediaItemListNumberPerPage === 50}]" @click="onNumberPerPageChange($event, 50);">50</a> <a href="#" :title="text.show + ' ' + text.all" class="images-number-select" :class="[{'active' : mediaItemListNumberPerPage === 10000}]" @click="onNumberPerPageChange($event, 10000);">{{text.all}}</a></span>
                            </div>

                            <ul class="options-accordion-image-list" v-if="mediaItemList.length > 0">
                                <li class="options-image-list-item" v-for="row in mediaItemList" :key="row.id">
                                    <label :for="element.name + '-chooseimagealt' + row.id" class="form-checkbox image image-border" :class="[{'image-disabled' : selectedOptions.includes(row.id)}]"><input type="radio"  :id="element.name + '-chooseimagealt' + row.id" :value="row.id + (selectedOptions.includes(row.id) ? 'dupe' : '')" v-model="selectedOption" :disabled="selectedOptions.includes(row.id)"  @focus="onInputFocusSelectedOption" @blur="onInputBlurSelectedOption" /><span></span><img v-auth-src="mediaBasePath + row.thumbnailFilePath" :alt="row.name | cleanString" /></label>
                                    <p class="image-title">{{row.name}}</p>
                                </li>
                            </ul>
                            <p class="pl-4 pt-4 pb-4" v-else>{{text.no_results}}</p>

                            <Pagination v-if="mediaItemListPagination.totalPages > 0" :pagination="mediaItemListPagination" @go-previous="refreshMediaItemList" @go-next="refreshMediaItemList" />
                        </div>
                    </div>
                </div>

                <a href="#" :title="text.select_different_image" @click="triggerShowExtraImages($event);" v-if="!showExtraImages">{{text.select_different_image}}</a> <span v-if="!element.required" :class="[{'pt-4 inline-block': showExtraImages}]"><span v-if="!showExtraImages"> | </span><a href="#" :title="text.cancel_image" @click="disableMediaPickerOverride($event)">{{text.cancel_image}}</a></span>
            </div>

            <span class="error-message" v-if="error">{{error}}</span>
        </div>
        <a href="#" :title="text.select_image" @click="enableMediaPickerOverride($event)" v-else>{{text.select_image}}</a>
    </div>
</template>

<script>
    import lang from '@/shared/lang';

    import Label from "@/components/form/label";
    import Pagination from "@/components/pagination";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {formElementMixin} from "@/components/mixins/formelement";
    import {formElementPickerMixin} from "@/components/mixins/formelement_picker";
    import {formElementMediaPickerMixin} from "@/components/mixins/formelement_mediapicker";

    export default {
        name: "Formelement_mediapicker_select",
        components: {Label, Pagination},
        mixins: [formatContentMixin, formElementMixin, formElementPickerMixin, formElementMediaPickerMixin],
        data() {
            return {
                showExtraImages: false,
                selectedOption: false,
                pickerEnabledOverride: false,
            }
        },
        props: {
            element: {
                type: Object
            },
            value: {
                type: [String,Number]
            },
            classString: {
                type: String
            }
        },
        computed: {
            text() {
                return lang;
            },
            pickerEnabled() {
                return this.element.required ? true : (this.pickerEnabledOverride || this.value);
            },
            inputValue: {
                get() { return parseInt(this.value) },
                set(inputValue) {this.$emit('input', inputValue)}
            },
        },
        methods: {
            async triggerShowExtraImages(e) {
                e.preventDefault();
                this.showExtraImages = !this.showExtraImages;

                await this.initMediaGroupSelect();
            },
            onNumberPerPageChange(e, newValue){
                e.preventDefault();
                this.changeNumberPerPage(newValue);
            },
            enableMediaPickerOverride(e){
                e.preventDefault();
                this.pickerEnabledOverride = true;
            },
            disableMediaPickerOverride(e){
                e.preventDefault();
                this.value = null;
                this.selectedOption = false;
                this.pickerEnabledOverride = false;
            },
        },
        async mounted() {
            if(this.value && this.value != ""){
                this.selectedOption = this.value;

                if(!this.selectedOptions.includes(this.value) ){
                    this.selectedOptions.push(this.value);
                }
            }

            await this.loadMediaItemsByID(this.selectedOptions);
        },
        watch:{
            selectedMediaGroupID: {
                handler: function() {
                    this.refreshMediaItemList();
                },
            },
            selectedOption: {
                handler: function() {
                    this.inputValue = this.selectedOption ? this.selectedOption : "";
                },
            }
        },
    }
</script>