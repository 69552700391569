<template>
    <div class="form-input" :class="[classString, {'error': error}, {'locked': element.locked === true, 'unlocked' : element.locked === false}]">
        <span v-if="element.isTemplateContentEdit" class="padlock-wrapper"><label><Label :label="element.label" :tooltip="element.tooltip" /></label><Lock :locked="element.locked" @toggleLocked="toggleLockedState($event)" /></span>
        <label v-else class="lock"><Label :label="element.label" :tooltip="element.tooltip" /></label>

        <label :for="element.name" class="form-checkbox">
            <input autocomplete="off" type="checkbox" :id="element.name" v-model="inputValue" :disabled="element.disabled" :readonly="element.readonly" @focus="onInputFocusStandard" @blur="onInputBlurStandard" /><span></span> {{element.text}}
        </label>
        <span class="error-message" v-if="error">{{error}}</span>
    </div>
</template>

<script>

    import {formElementMixin} from "@/components/mixins/formelement";

    import Label from "@/components/form/label";
    import Lock from "@/components/form/lock";

    export default {
        name: "Formelement_checkbox",
        components: {Label, Lock},
        mixins: [formElementMixin],
        props: {
            element: {
                type: Object
            },
            value: {
                type: [String, Boolean]
            },
            classString: {
                type: String
            }
        },
        computed: {
            inputValue: {
                get() { return this.value },
                set(inputValue) {this.$emit('input', inputValue)}
            }
        },
    }
</script>