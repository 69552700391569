// define a mixin object
//import {log} from "@/shared/utils";
//import lang from "@/shared/lang";

import {log, showDefaultServerErrorMessage} from '@/shared/utils';
import {MEDIA_ROOT} from "@/shared/consts";

import {getMediaGroupList, getMediaItemsByMediaGroupID, getMediaItemsByID} from "@/shared/dataservice";

export const formElementMediaPickerMixin = {
    data() {
        return {
            mediaGroupSelectOptions : [],
            selectedMediaGroupID : 0,
            mediaItemList : [],
            mediaItemShortList : [],
            mediaItemListPagination : [],
            mediaItemListNumberPerPage : 10
        }
    },
    computed: {
        mediaBasePath() {
            return MEDIA_ROOT;
        },
    },
    methods: {
        async initMediaGroupSelect() {
            log(["Init Media Group Select"]);
            let result = await getMediaGroupList();

            log(["Loaded media groups", result]);

            if(result.status === 200){
                let newOptions = [];
                result.data.data.forEach(mediaGroup => {
                    newOptions.push({value : mediaGroup.id, text : mediaGroup.name});
                });
                log(["New options", newOptions]);
                this.mediaGroupSelectOptions = newOptions;
            }
            else
            {
                showDefaultServerErrorMessage("Load Media Groups");
            }
        },
        async loadMediaGroupMediaByID(id, page = 1){

            log(["Getting Media by group id", id, page, this.mediaItemListNumberPerPage]);

            let result = await getMediaItemsByMediaGroupID(id, page, this.mediaItemListNumberPerPage);

            if(result.status === 200){
                this.mediaItemList = result.data.data;
                this.mediaItemListPagination = result.data.meta;
            }
            else
            {
                showDefaultServerErrorMessage("Load Media Groups Items");
            }
        },
        async refreshMediaItemList(page = 1){
            await this.loadMediaGroupMediaByID(this.selectedMediaGroupID, page);
        },
        async changeNumberPerPage(newValue) {
            this.mediaItemListNumberPerPage = newValue;
            await this.refreshMediaItemList();
        },
        async loadMediaItemsByID(ids){

            log(["Getting media itemS by ID", ids]);

            let result = await getMediaItemsByID({ id : ids });

            if(result.status === 200){
                this.mediaItemShortList = result.data.data;
            }
            else
            {
                showDefaultServerErrorMessage("Load Media Items");
            }
        }
    },
}