<template>
    <div class="form-input options options-select" :class="[classString, {'error': error}, {'locked': element.locked === true, 'unlocked' : element.locked === false}]"> <!-- locked or nothing -->
        <label :for="element.name" class="lock"><Label :label="element.label" :tooltip="element.tooltip" /></label>

        <div class="form-select mb-2" v-if="!element.ignoreOptions && (getSelectedOptionElements.length > 0)">
            <select autocomplete="off" :id="element.name" v-model="selectedOption" :disabled="element.disabled" :readonly="element.readonly" @focus="onInputFocusSelectedOption" @blur="onInputBlurSelectedOption">
                <option value="0" disabled>- {{text.select_option}} -</option>
                <option v-for="(option, index) in getSelectedOptionElements" :key="index" :value="option.id">{{ getOptionLabel(option.name, option.value) }}</option>
            </select>
        </div>

        <input autocomplete="off" type="text" :id="element.name" :placeholder="element.placeholder" v-model="inputValue" :readonly="element.locked" @focus="onInputFocusStandard" @blur="onInputBlurStandard" >
        <span class="error-message" v-if="error">{{error}}</span>
    </div>
</template>

<script>
    import {log} from '@/shared/utils';
    import lang from '@/shared/lang';

    import Label from "@/components/form/label";

    import {formElementMixin} from "@/components/mixins/formelement";
    import {formElementPickerMixin} from "@/components/mixins/formelement_picker";

    export default {
        name: "Formelement_textpicker_select",
        components: {Label},
        mixins: [formElementMixin, formElementPickerMixin],
        props: {
            element: {
                type: Object
            },
            value: {
                type: [String, Array]
            },
            classString: {
                type: String
            }
        },
        data() {
            return {
                selectedOption: 0
            }
        },
        computed: {
            text() {
                return lang;
            },
            inputValue: {
                get() { return this.value },
                set(inputValue) {this.$emit('input', inputValue)}
            },
        },
        methods: {
            clear() {
                document.getElementById(this.element.name).value = "";
            },
            updateSelectedOption() {
                log(["Update selected option", this.inputValue, this.selectedOption, this.getSelectedOptionElements.find(option => { return option.value === this.inputValue })]);
                this.selectedOption = this.getSelectedOptionElements.find(option => { return option.value === this.inputValue }) ? this.getSelectedOptionElements.find(option => { return option.value === this.inputValue }).id : 0;
            },
            updateValue() {
                log(["Update value", this.inputValue, this.selectedOption, this.getSelectedOptionElements, this.getSelectedOptionElements.find(option => { return option.id === this.selectedOption })]);
                this.inputValue = this.getSelectedOptionElements.find(option => { return option.id === this.selectedOption }) ? this.getSelectedOptionElements.find(option => { return option.id === this.selectedOption }).value : "";
            },
        },
        watch:{
            inputValue: {
                handler: function() {
                    this.updateSelectedOption();
                },
                immediate: true
            },
            selectedOption: {
                handler: function() {
                    if(this.selectedOption !== 0) {
                        this.updateValue();
                    }
                },
            }
        },
    }
</script>