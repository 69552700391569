<template>
    <div class="pagination">
        <a href="#" :title="text.pagination_previous" v-show="pagination.currentPage > 1" @click="goPrevious($event)" >{{text.pagination_previous}}</a>
        <a href="#" :title="text.pagination_next" v-show="pagination.currentPage != pagination.totalPages" @click="goNext($event)">{{text.pagination_next}}</a>
        <span>{{overview}}</span>
    </div>
</template>

<script>
    import lang from "@/shared/lang";
    import {log} from "@/shared/utils";

    export default {
        name: "Pagination",
        props: {
            pagination: {
                type: Object
            },
        },
        computed: {
            text() {
                return lang;
            },
            overview() {
                return this.text.pagination_overview.replace("%1%", this.pagination.currentPage).replace("%2%", this.pagination.totalPages);
            }
        },
        methods: {
            goPrevious(e) {
                e.preventDefault();

                log(["Sending Go Previous Event", (parseInt(this.pagination.currentPage) - 1)]);

                this.$emit('go-previous', (parseInt(this.pagination.currentPage) - 1))
            },
            goNext(e) {
                e.preventDefault();

                log(["Sending Go Next Event", (parseInt(this.pagination.currentPage) + 1)]);
                this.$emit('go-next', (parseInt(this.pagination.currentPage) + 1))
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>