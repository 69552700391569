<template>
    <div class="form-input options options-select" :class="[classString, {'error': error}, {'locked': element.locked === true, 'unlocked' : element.locked === false}]"> <!-- locked or nothing -->
        <label :for="element.name" class="lock"><Label :label="labelText" :tooltip="element.tooltip" /></label>

        <div class="options-accordion open" v-if="!ignoreOptions">
            <span class="options-accordion-heading">{{text.content_options}}</span>
            <ul class="options-accordion-list" v-if="selectedOptions.length > 0">
                <li class="options-item" v-for="row in getSelectedOptionElements" :key="row.id">
                    <span v-if="row.name"><strong>{{row.name}}</strong><br/>{{row.value}}</span><span v-else>{{row.value}}</span>
                </li>
            </ul>
            <ul class="options-accordion-list" v-else>
                <li class="options-item">{{text.no_options_selected}}</li>
            </ul>
        </div>

        <textarea-autosize :id="element.name" :placeholder="element.placeholder" :rows="numRows" cols="50" v-model="inputValue" :disabled="element.disabled" :readonly="element.readonly" v-else></textarea-autosize>
        <span class="error-message" v-if="error">{{error}}</span>
    </div>
</template>

<script>
    import lang from '@/shared/lang';

    import Label from "@/components/form/label";

    import {formElementMixin} from "@/components/mixins/formelement";
    import {formElementPickerMixin} from "@/components/mixins/formelement_picker";

    export default {
        name: "Formelement_textpicker_view",
        components: {Label},
        mixins: [formElementMixin, formElementPickerMixin],
        props: {
            element: {
                type: Object
            },
            value: {
                type: [String, Array]
            },
            classString: {
                type: String
            }
        },
        computed: {
            text() {
                return lang;
            },
            inputValue: {
                get() { return this.value },
                set(inputValue) {this.$emit('input', inputValue)}
            },
            numRows() {
                return this.element.rows ? this.element.rows : 4;
            },
        },
    }
</script>