<template>
    <div class="form-input" :class="[classString, {'error': error}]">
        <span class="label"><Label :label="element.label + ' ' + text.disabled_label_suffix" :tooltip="element.tooltip" /></span>
    </div>
</template>

<script>
    import lang from '@/shared/lang';

    import Label from "@/components/form/label";
    import {formElementMixin} from "@/components/mixins/formelement";

    export default {
        name: "Formelement_disabled",
        components: {Label},
        mixins: [formElementMixin],
        props: {
            element: {
                type: Object
            },
            value: {
                type: String
            },
            classString: {
                type: String
            }
        },
        computed: {
            text() {
                return lang;
            },
            inputValue: {
                get() { return this.value },
                set(inputValue) {this.$emit('input', inputValue)}
            }
        },
        methods: {
            clear() {
                document.getElementById(this.element.name).value = "";
            }
        },
    }
</script>