<template>
  <div>
    <div class="help" v-if="currentHelp.message">
        <span v-html="currentHelp.message"></span>
        <a href="#" :title="currentHelp.closebtn" class="close" @click="hideHelp($event)">{{currentHelp.closebtn}}</a>
    </div>
    <a href="#" :title="text.help_restore_btn" class="restore-help" @click="showHelp($event)" v-else>{{text.help_restore_btn}}</a>
  </div>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import lang from '@/shared/lang';
    import {getHelpForRoute} from "@/shared/dataservice";

    export default {
        name: "Help",
        data() {
            return {
                currentHelp: {
                    message : "",
                    closebtn : lang.help_close_btn
                }
            }
        },
        computed: {
            ...mapState([ 'helpMessages' ]), // imports from store
            helpMessages() {
                return this.$store.state.helpMessages;
            },
            hiddenHelpMessages() {
                return this.$store.state.hiddenHelpMessages;
            },
            currentPageName() {
                return this.$route.name;
            },
            text() {
              return lang;
            }
        },
        mounted() {
            // make sure hidden states are preloaded from local storage on first load
            this.setHiddenHelpMessagesAction();

            // then make sure help is show for this page if required
            this.showHelpForRoute(this.currentPageName);
        },
        methods: {
            ...mapActions(["setHelpMessageForRouteAction","setHiddenHelpMessageForRouteAction", "setRestoreHelpMessageForRouteAction","setHiddenHelpMessagesAction"]),
            async showHelpForRoute(routeName) {
                if(this.hiddenHelpMessages.indexOf(routeName) < 0) {
                    if (!this.helpMessages[routeName]) {
                        await this.getHelpMessageForRoute(routeName);
                    }

                    this.currentHelp.message = this.helpMessages[routeName];
                }
                else
                {
                    this.currentHelp.message = "";
                }
            },
            async getHelpMessageForRoute(routeName) {
                let result = await getHelpForRoute(routeName);

                this.setHelpMessageForRouteAction({ routeName : routeName, message : result.data.data.body });
            },
            hideHelp(e) {
                e.preventDefault();

                this.setHiddenHelpMessageForRouteAction(this.currentPageName);

                // trigger a state check to hide this help now.
                this.showHelpForRoute(this.currentPageName);
            },
            showHelp(e) {
                e.preventDefault();

                this.setRestoreHelpMessageForRouteAction(this.currentPageName);

                // trigger a state check to hide this help now.
                this.showHelpForRoute(this.currentPageName);
            }
        },
        watch:{
            async $route (to){
                await this.showHelpForRoute(to.name);
            }
        }
    }
</script>