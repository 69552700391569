const qs = require('qs');

import axios from 'axios';
import { loadProgressBar } from 'axios-progress-bar'
loadProgressBar();
axios.defaults.paramsSerializer = p => {
    return qs.stringify(p, {arrayFormat: 'repeat'})
}

import router from '@/router';

import {API, MEDIA_ROOT, LOCAL_STORAGE_PREFIX} from '@/shared/consts';
import {log, showDefaultServerErrorMessage} from '@/shared/utils';

/**
 * AUTHENTICATION
 */
export const doLogin = async function (user) {
    try {
        //log(['try doLogin', API, user]);

        const response = await axios({
            url: `${API}/authenticate`,
            data: user,
            method: 'POST',
            withCredentials: true,
            crossorigin: true
        });

        //log(['try doLogin result', response]);

        return response;
    } catch (error) {
        //log(["Login error", error]);
        //showDefaultServerErrorMessage("Login");

        return error.response;
    }
};

export const doRefresh = async function () {
    try {
        //log(['try doRefresh', API]);

        const response = await axios({
            url: `${API}/authenticate`,
            data: {},
            method: 'GET',
            withCredentials: true,
            crossorigin: true
        });

        //log(['try doRefresh result', response]);

        return response;
    } catch (error) {
        //log(["Refresh error", error]);

        if(error.response.status === 401){
            handleUnauthorisedRequest();
        }
        else
        {
            showDefaultServerErrorMessage("Refresh");
        }


        return error.response;
    }
};

export const doLogout = async function () {
    try {
        //log(['try doLogout', API]);

        const response = await axios({
            url: `${API}/authenticate/logout`,
            data: {},
            method: 'POST',
            withCredentials: true,
            crossorigin: true
        });

        //log(['try doLogout result', response]);

        return response;
    } catch (error) {
        //log(["Logout error", error]);
        showDefaultServerErrorMessage("Logout");

        return error.response;
    }
};

/**
 * LOCALSTORAGE
 */
export const saveToLocalStorage = function (key, value) {
    window.localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${key}`, value);
}
export const getFromLocalStorage = function (key) {
    return window.localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${key}`);
}

/**
 * BUSINESS UNITS
 */
export const getBusinessUnits = function() {
    log(['Getting BusinessUnits']);

    return getFromAPI(`/BusinessUnit`);
}
export const getUserBusinessUnits = function () {
    log(['Getting USER BusinessUnits']);

    return getFromAPI(`/User/businessunits`);
}
export const getBusinessUnitByID = async function (id) {
    log(['getting BusinessUnit by id ', id]);

    return getFromAPI(`/BusinessUnit/${id}`);
}
export const saveBusinessUnitByID = async function (id, data) {
    log(['saving BusinessUnits by id', id, data]);

    return putToAPI(`/BusinessUnit/${id}`, data);
}
export const addBusinessUnit = async function (data) {
    log(['saving new BusinessUnit', data]);

    return postToAPI(`/BusinessUnit/`, data);
}
export const archiveBusinessUnitByID = async function (id) {
    log(['archiving BusinessUnits by id', id]);

    return postToAPI(`/BusinessUnit/${id}/archive`);
}
export const restoreBusinessUnitByID = async function (id) {
    log(['restoring BusinessUnits by id', id]);

    return postToAPI(`/BusinessUnit/${id}/restore`);
}

/**
 * IN PAGE HELP
 */
export const getHelpForRoute = async function (routeAlias) {
    log(['getting Help for route', routeAlias]);

    return getFromAPI(`/PageHelpDocument/${routeAlias}`);
}
export const getHelpByID = async function (id) {
    log(['getting Help for id', id]);

    return getFromAPI(`/PageHelpDocument/${id}`);
}
export const saveHelpByID = async function (id, data) {
    log(['saving Help for id', id, data]);

    return putToAPI(`/PageHelpDocument/${id}`, data);
}
export const getAllInPageHelp = async function () {
    log(['getting all In page Help']);

    return getFromAPI('/PageHelpDocument/');
}

/**
 * TEMPLATE SCHEMA SETTINGS COLLECTION
 */
export const getAllTemplateSchemaSettingsCollections = async function () {
    log(['getting all TemplateSchemaSettingsCollections']);

    return getFromAPI(`/TemplateSchemaSettingsCollection/`);
}
export const getTemplateSchemaSettingsCollectionByID = async function (id = -1) {
    log(['getting TemplateSchemaSettingsCollection by ID', id]);

    return getFromAPI(`/TemplateSchemaSettingsCollection/${id}`);
}
export const saveTemplateSchemaSettingsCollectionByID = async function (id = -1, data) {
    log(['saving TemplateSchemaSettingsCollection by ID', id, data]);

    return putToAPI(`/TemplateSchemaSettingsCollection/${id}`, data);
}
export const getTemplateSchemaSettingsCollectionPropertyByAlias = async function (id, alias) {
    log(['getting TemplateSchemaSettingsCollectionProperty for Alias', id, alias]);

    return getFromAPI(`/TemplateSchemaSettingsCollection/${id}/property/${alias}`);
}
export const saveTemplateSchemaSettingsCollectionPropertyByAlias = async function (id, alias, data) {
    log(['saving templateSchemaSettingsCollectionProperty for Alias', id, alias, data]);

    return putToAPI(`/TemplateSchemaSettingsCollection/${id}/property/${alias}`, data);
}
export const getTemplateDescriptionFromTemplateSchemaSettingsCollectionByID = async function (id) {
    log(['Getting template description from schema by id', id]);

    return getFromAPI(`/TemplateSchemaSettingsCollection/${id}/templatedescription`);
}

/**
 * FAQs
 */
export const getFAQs = async function () {
    log(['getting FAQs']);

    return getFromAPI(`/Faq/`);
}
export const saveFAQs = async function (data) {
    log(['saving FAQs', data]);

    return putToAPI(`/Faq/`, data);
}

/**
 * ADVERTS
 */
export const getAdvertList = async function (activeState = true, page = 1, filters = []) {

    let params = { 'activeState' : activeState, 'Page' : page };
    filters.forEach((element) => {
        log(["Looping through filters", element]);
        params[element.name] = element.value;
    });

    log(['getting advert list by active state', activeState, page, filters, params]);

    return getFromAPI(`/AdvertSearch/`, params);
}
export const getUserActiveAdvertList = async function (page = 1, filters = []) {

    let params = { 'activeState' : true, 'Page' : page, 'CurrentUserOnly' : true };
    filters.forEach((element) => {
        log(["Looping through filters", element]);
        params[element.name] = element.value;
    });

    log(['getting USERS advert list by active state', true, page, filters, params]);

    return getFromAPI(`/AdvertSearch/`, params);
}
export const getAdvertListDraft = async function (page = 1, filters = []) {

    let params = { 'Page' : page };
    filters.forEach((element) => {
        params[element.name] = element.value;
    });

    log(['getting draft advert list by active state', page, filters, params]);

    return getFromAPI(`/AdvertSearch/drafts`, params);
}
export const getAdvertListArchive = async function (page = 1, filters = []) {
    log(['getting archive advert list by active state', page, filters]);

    let params = { 'Page' : page };
    filters.forEach((element) => {
        params[element.name] = element.value;
    });

    log(['getting archive advert list by active state', page, filters, params]);

    return getFromAPI(`/AdvertSearch/archived`, params);
}
export const getAdvertByID = async function (id) {
    log(['getting advert by id ', id]);

    return getFromAPI(`/Advert/${id}`);
}
export const saveAdvertByID = async function (id, data) {
    log(['saving Advert by id', id, data]);

    return putToAPI(`/Advert/${id}`, data);
}
export const publishAdvertByID = async function (id) {
    log(['publish Advert by id', id]);

    return postToAPI(`/Advert/${id}/publish`);
}

export const archiveAdvertByID = async function (id) {
    log(['archiving Advert by id', id]);

    return postToAPI(`/Advert/${id}/archive`);
}
export const restoreAdvertByID = async function (id) {
    log(['restoring Advert by id', id]);

    return postToAPI(`/Advert/${id}/restore`);
}
export const copyAdvertByID = async function (id) {
    log(['copying Advert by id', id]);

    return postToAPI(`/Advert/${id}/copy`);
}
export const createNewAdvert = async function (data) {
    log(['creating new advert', data]);

    return postToAPI(`/Advert/`, data);
}
export const downloadPublishFormat = async function (advertID, publishedFormatKey){
    return downloadSecureFile(`/publishedformat/${advertID}/Original/${publishedFormatKey}`, {}, {
            'Accept' : "*/*",
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        });
}

/**
 * MEDIA GROUP
 */
export const getMediaGroupList = async function () {
    log(['getting media groups list']);

    return getFromAPI(`/MediaGroup/`);
}
export const getMediaGroupArchiveList = async function () {
    log(['getting media groups list']);

    return getFromAPI(`/MediaGroup/archived`);
}
export const addMediaGroup = async function (data) {
    log(['adding media groups', data]);

    return postToAPI(`/MediaGroup/`, data);
}
export const getMediaGroupByID = async function (id, page) {
    log(['getting media group by id ', id, page]);
    let params = { 'Page' : page };
    return getFromAPI(`/MediaGroup/${id}`, params);
}
export const saveMediaGroupByID = async function (id, data) {
    log(['save media group by id ', id, data]);

    return putToAPI(`/MediaGroup/${id}`, data);
}
export const archiveMediaGroupByID = async function (id) {
    log(['archiving media group by id', id]);

    return postToAPI(`/MediaGroup/${id}/archive`);
}
export const restoreMediaGroupByID = async function (id) {
    log(['restore media group by id', id]);

    return postToAPI(`/MediaGroup/${id}/restore`);
}
export const uploadToMediaGroupByID = async function (id, data) {
    log(['uploading to media group by id', id, data]);

    return postToAPI(`/MediaGroup/${id}/upload`, data);
}
export const getMediaItemsByMediaGroupID = async function (mediaGroupID, page, numberPerPage = false) {
    log(['getting media items by media group id ', mediaGroupID, page]);
    let params = { 'Page' : page, 'MediaGroupId' : mediaGroupID };

    if(numberPerPage !== false){
        params.ItemsPerPage = numberPerPage;
    }

    return getFromAPI(`/MediaItemSearch`, params);
}
export const getArchiveMediaItemsByMediaGroupID = async function (mediaGroupID, page) {
    log(['getting archive media items by media group id ', mediaGroupID, page]);
    let params = { 'Page' : page, 'MediaGroupId' : mediaGroupID };

    return getFromAPI(`/MediaItemSearch/archived`, params);
}

/**
 * MEDIA ITEM
 */
export const getMediaItemByID = async function (id) {
    log(['getting media item', id]);

    return getFromAPI(`/MediaItem/${id}`);
}
export const getMediaItemsByID = async function (ids) {
    log(['getting media items', ids]);

    return getFromAPI(`/MediaItem/`, ids);
}
export const saveMediaItemByID = async function (id, data) {
    log(['save media item by id ', id]);

    return putToAPI(`/MediaItem/${id}`, data);
}
export const archiveMediaItemByID = async function (id) {
    log(['archiving media item by id', id]);

    return postToAPI(`/MediaItem/${id}/archive`);
}
export const restoreMediaItemByID = async function (id) {
    log(['restore media item by id', id]);

    return postToAPI(`/MediaItem/${id}/restore`);
}
export const archiveMediaItemsByIDs = async function (data) {
    log(['archiving media items by ids', data]);

    return postToAPI(`/bulkactions/mediaitems/archive`, data);
}
export const restoreMediaItemsByIDs = async function (data) {
    log(['restore media items by ids', data]);

    return postToAPI(`/bulkactions/mediaitems/restore`, data);
}

/**
 * IMAGE EDIT
 */
export const getImageCreationOptions = async function() {
    log(['getting image creation options']);
    return getFromAPI(`/ImageCreationOptions`);
}
export const getImageEditPreview = async function(data) {
    log(['getting preview image', data]);

    const path = '/croppreview';
    try {
        log(['postToMediaItem', MEDIA_ROOT, path, data]);

        const response = await axios({
            url: `${MEDIA_ROOT}${path}`,
            data: data,
            method: 'POST',
            crossorigin: true
        });

        log(['postToMediaItem result', path, response]);

        return response;
    } catch (error) {
        log(["postToMediaItem error", path, error]);
        showDefaultServerErrorMessage("POST");

        if(error.response.status === 401){
            handleUnauthorisedRequest();
        }

        return error.response;
    }
}
export const saveMediaItemCropByID = async function(id, data) {
    log(['save Media Item Crop By ID', id, data]);

    return putToAPI(`/MediaItemCrop/${id}`, data);
}

export const createMediaItemFromCrop = async function(data) {
    log(['create Media Item From Crop', data]);

    return postToAPI(`/MediaItemCrop/`, data);
}

/**
 * TEMPLATES
 */
export const getTemplateList = async function (page = 1) {

    let params = { 'Page' : page };

    log(['getting template list', page, params]);

    return getFromAPI(`/TemplateSearch/`, params);
}
export const getTemplateListArchive = async function (page = 1) {
    log(['getting archive Template list', page]);

    let params = { 'Page' : page };

    log(['getting archive Template list by', page, params]);

    return getFromAPI(`/TemplateSearch/archived`, params);
}
export const getTemplateByID = async function (id) {
    log(['getting template by id ', id]);

    return getFromAPI(`/Template/${id}`);
}
export const saveTemplateByID = async function (id, data) {
    log(['saving Template by id', id, data]);

    return putToAPI(`/Template/${id}`, data);
}
export const archiveTemplateByID = async function (id) {
    log(['archiving Template by id', id]);

    return postToAPI(`/Template/${id}/archive`);
}
export const restoreTemplateByID = async function (id) {
    log(['restoring Template by id', id]);

    return postToAPI(`/Template/${id}/restore`);
}
export const copyTemplateByID = async function (id) {
    log(['copying Template by id', id]);

    return postToAPI(`/Template/${id}/copy`);
}
export const createNewTemplate = async function (data) {
    log(['creating new template', data]);

    return postToAPI(`/Template/`, data);
}
export const getAdvertDescriptionByTemplateID = async function (id) {
    log(['Getting advert description from template by id', id]);

    return getFromAPI(`/Template/${id}/advertdescription`);
}

/**
 * USER
 */
export const getUserList = async function (page = 1) {

    let params = { 'Page' : page };

    log(['getting user list', page, params]);

    return getFromAPI(`/UserSearch`, params);
}
export const createNewUser = async function (data) {

    log(['creating new user', data]);

    return postToAPI(`/UserAdmin/`, data);
}
export const getUserByID = async function (id) {
    log(['getting user by id', id]);

    return getFromAPI(`/UserAdmin/${id}`);
}
export const saveUserByID = async function (id, data) {
    log(['saving user by id', id, data]);

    return putToAPI(`/UserAdmin/${id}`, data);
}
export const enableUserByID = async function (id) {

    log(['enable user', id]);

    return postToAPI(`/UserAdmin/${id}/enable`);
}
export const disableUserByID = async function (id) {

    log(['disable user', id]);

    return postToAPI(`/UserAdmin/${id}/disable`);
}
export const getUserRoles = async function () {
    log(['getting user roles']);

    return getFromAPI(`/UserRoles`);
}

/**
 * USER UTILS
 */
export const confirmUserEmailByID = async function (id, data){
    log(['Confirm user email', id, data]);
    return postToAPI(`/User/confirmemail/${id}`, data);
}
export const triggerResetPassword = async function (data){
    log(['Trigger reset password', data]);
    return postToAPI(`/User/beginresetpassword`, data);
}
export const doResetPassword = async function(id, data){
    log(['Do reset password', id, data]);
    return postToAPI(`/User/resetpassword/${id}`, data);
}

/**
 * MESSAGES
 */
export const getUserUnreadMessagesList = async function (page = 1) {

    let params = { 'Page' : page };

    log(['getting message list unread', page, params]);

    return getFromAPI(`/MessageForUserSearch/unread`, params);
}
export const getUserMessagesList = async function (page = 1) {

    let params = { 'Page' : page };

    log(['[USER] getting message list', page, params]);

    return getFromAPI(`/MessageForUserSearch`, params);
}
export const getUserMessagesListArchive = async function (page = 1) {
    log(['getting message list archive', page]);

    let params = { 'Page' : page };

    log(['[USER] getting message list archive', page, params]);

    return getFromAPI(`/MessageForUserSearch/archived`, params);
}
export const getUserNextUnreadMessages = async function () {
    log(['getting user next unread message']);

    let params = { 'Page' : 1,  'ItemsPerPage' : 1};

    log(['[USER] user next unread message', params]);

    return getFromAPI(`/MessageForUserSearch/unread`, params);
}
export const getAdminMessagesList = async function (page = 1) {

    let params = { 'Page' : page };

    log(['[ADMIN] getting message list', page, params]);

    return getFromAPI(`/MessageSearch`, params);
}
export const getAdminMessagesPreviewList = async function (numberToShow = 10) {

    let params = { 'ItemsPerPage' : numberToShow, 'Page' : 1 };

    log(['[ADMIN] getting message preview list', numberToShow, params]);

    return getFromAPI(`/MessageSearch`, params);
}
export const getAdminMessagesListArchive = async function (page = 1) {
    log(['getting message advert list by active state', page]);

    let params = { 'Page' : page };

    log(['[ADMIN] getting message list archive', page, params]);

    return getFromAPI(`/MessageSearch/archived`, params);
}
export const getUserMessageByID = async function (id) {
    log(['getting user message by id ', id]);

    return getFromAPI(`/MessageForUser/${id}`);
}

export const getMessageByID = async function (id) {
    log(['getting message by id ', id]);

    return getFromAPI(`/Message/${id}`);
}
export const markMessageAsReadByID = async function (id) {
    log(['making message read by id ', id]);

    return putToAPI(`/messageforuser/${id}/read`);
}
export const saveMessageByID = async function (id, data) {
    log(['saving message by id', id, data]);

    return putToAPI(`/Message/${id}`, data);
}
export const addMessage = async function (data) {
    log(['adding message', data]);

    return postToAPI(`/Message/`, data);
}
export const deleteMessageByID = async function (id) {
    log(['deleting message by id ', id]);

    return deleteFromAPI(`/Message/${id}`);
}
export const archiveMessageByID = async function (id) {
    log(['archiving message by id ', id]);

    return postToAPI(`/Message/${id}/archive`);
}

/**
 * REPORTS
 */
export const getReportAdvertsPublished = async function (params, triggerDownload = false) {
    log(['getting advertspublished report', params]);

    let headers = triggerDownload ? { Accept : "text/csv" } : null;

    return triggerDownload ? downloadFromAPI(`/Reports/advertspublished`,params, headers) : getFromAPI(`/Reports/advertspublished`,params);
}
export const getReportCaseLoadBusinessUnit = async function (params, triggerDownload = false) {
    log(['getting advertspublished report', params]);

    let headers = triggerDownload ? { Accept : "text/csv" } : null;

    return triggerDownload ? downloadFromAPI(`/Reports/businessunitcaseload`,params, headers) : getFromAPI(`/Reports/businessunitcaseload`,params);
}
export const getReportCaseLoadUser = async function (params, triggerDownload = false) {
    log(['getting user case load report', params]);

    let headers = triggerDownload ? { Accept : "text/csv" } : null;

    return triggerDownload ? downloadFromAPI(`/Reports/usercaseload`,params, headers) : getFromAPI(`/Reports/usercaseload`,params);
}

// export all methods you want as part of the default batch
export default {
    doLogin,
    doRefresh,
    doLogout,

    getFAQs,
    saveFAQs,

    getHelpForRoute,
    getHelpByID,
    saveHelpByID,
    getAllInPageHelp,

    getAllTemplateSchemaSettingsCollections,
    getTemplateSchemaSettingsCollectionByID,
    saveTemplateSchemaSettingsCollectionByID,
    getTemplateSchemaSettingsCollectionPropertyByAlias,
    saveTemplateSchemaSettingsCollectionPropertyByAlias,
    getTemplateDescriptionFromTemplateSchemaSettingsCollectionByID,

    getAdvertList,
    getAdvertListDraft,
    getAdvertListArchive,

    getAdvertByID,
    saveAdvertByID,
    archiveAdvertByID,
    restoreAdvertByID,
    copyAdvertByID,
    createNewAdvert,

    getMediaGroupList,
    getMediaGroupArchiveList,
    addMediaGroup,
    getMediaGroupByID,
    saveMediaGroupByID,
    archiveMediaGroupByID,
    restoreMediaGroupByID,
    uploadToMediaGroupByID,
    getMediaItemsByMediaGroupID,
    getArchiveMediaItemsByMediaGroupID,

    getMediaItemByID,
    getMediaItemsByID,
    saveMediaItemByID,
    archiveMediaItemByID,
    restoreMediaItemByID,
    archiveMediaItemsByIDs,
    restoreMediaItemsByIDs,
    getImageCreationOptions,
    getImageEditPreview,
    saveMediaItemCropByID,
    createMediaItemFromCrop,

    getTemplateList,
    getTemplateListArchive,
    getTemplateByID,
    saveTemplateByID,
    archiveTemplateByID,
    restoreTemplateByID,
    copyTemplateByID,
    createNewTemplate,
    getAdvertDescriptionByTemplateID,

    getUserUnreadMessagesList,
    getUserMessagesList,
    getUserMessagesListArchive,
    getAdminMessagesList,
    getAdminMessagesPreviewList,
    getAdminMessagesListArchive,
    getUserNextUnreadMessages,

    getUserList,
    createNewUser,
    getUserByID,
    saveUserByID,
    enableUserByID,
    disableUserByID,
    getUserRoles,

    confirmUserEmailByID,
    triggerResetPassword,

    getMessageByID,
    getUserMessageByID,
    markMessageAsReadByID,
    saveMessageByID,
    addMessage,
    deleteMessageByID,

    getBusinessUnits,
    getUserBusinessUnits,
    getBusinessUnitByID,
    saveBusinessUnitByID,
    addBusinessUnit,
    archiveBusinessUnitByID,
    restoreBusinessUnitByID,

    saveToLocalStorage,
    getFromLocalStorage,

    getReportAdvertsPublished,
    getReportCaseLoadBusinessUnit,
    getReportCaseLoadUser,
};

/**
 * *****************************
 * PRIVATE FUNCTIONS
 * *****************************
 */

/**
 * GENERAL GET
 */
async function getFromAPI (path, params = {}) {
    try {
        log(['getFromAPI', API, path, params]);

        const response = await axios({
            url: `${API}${path}`,
            params: params,
            method: 'GET',
            crossorigin: true,
        });

        log(['getFromAPI result', path, response]);

        return response;
    } catch (error) {
        log(["getFromAPI error", path, error]);
        showDefaultServerErrorMessage("GET");

        if(error.response.status === 401){
            handleUnauthorisedRequest();
        }

        return error.response;
    }
}

/**
 * DOWNLOAD GET
 */
async function downloadFromAPI (path, params = {}, headers = {}) {
    try {
        log(['downloadFromAPI', API, path, params, headers]);

        const response = await axios({
            url: `${API}${path}`,
            params: params,
            method: 'GET',
            crossorigin: true,
            headers: {...axios.defaults.headers.common, ...headers},
            responseType: 'blob',
        });

        log(['downloadFromAPI result', path, response]);

        return response;
    } catch (error) {
        log(["downloadFromAPI error", path, error]);
        showDefaultServerErrorMessage("DOWNLOAD");

        if(error.response.status === 401){
            handleUnauthorisedRequest();
        }

        return error.response;
    }
}

/**
 * GENERAL PUT
 */
async function putToAPI(path, data = {}) {
    try {
        log(['putToAPI', API, path, data]);

        const response = await axios({
            url: `${API}${path}`,
            data: data,
            method: 'PUT',
            crossorigin: true
        });

        log(['putToAPI result', path, response]);

        return response;
    } catch (error) {
        log(["putToAPI error", path, error]);
        showDefaultServerErrorMessage("PUT");

        if(error.response.status === 401){
            handleUnauthorisedRequest();
        }

        return error.response;
    }
}

/**
 * GENERAL PUT
 */
async function postToAPI(path, data = {}) {
    try {
        log(['postToAPI', API, path, data]);

        const response = await axios({
            url: `${API}${path}`,
            data: data,
            method: 'POST',
            crossorigin: true
        });

        log(['postToAPI result', path, response]);

        return response;
    } catch (error) {
        log(["postToAPI error", path, error]);
        showDefaultServerErrorMessage("POST");

        if(error.response.status === 401){
            handleUnauthorisedRequest();
        }

        return error.response;
    }
}

/**
 * GENERAL DELETE
 */
async function deleteFromAPI(path, data) {
    try {
        log(['deleteFromAPI', API, path, data]);

        const response = await axios({
            url: `${API}${path}`,
            data: data,
            method: 'DELETE',
            crossorigin: true
        });

        log(['deleteFromAPI result', path, response]);

        return response;
    } catch (error) {
        log(["deleteFromAPI error", path, error]);
        showDefaultServerErrorMessage("DELETE");

        if(error.response.status === 401){
            handleUnauthorisedRequest();
        }

        return error.response;
    }
}

/**
 * Export file format
 */
async function downloadSecureFile(path, params = {}, headers = {}) {
    try {
        log(['downloadSecureFile', MEDIA_ROOT, path, params, headers]);

        const response = await axios({
            url: `${MEDIA_ROOT}${path}`,
            params: params,
            method: 'GET',
            crossorigin: true,
            headers: {...axios.defaults.headers.common, ...headers},
            responseType: 'blob',
        });

        log(['downloadSecureFile result', path, response]);

        return response;
    } catch (error) {
        log(["downloadSecureFile error", path, error]);
        showDefaultServerErrorMessage("DOWNLOAD");

        if(error.response.status === 401){
            handleUnauthorisedRequest();
        }

        return error.response;
    }
}

/**
 * Reset LocalStorage
 */
function handleUnauthorisedRequest(){
    router.push( { name: 'Login', params : { action :'nopermission' }} );
}