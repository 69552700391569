(function () {
    var vueAuthImage = {};
    var axios = typeof require === 'function'
        ? require('axios')
        : window.Axios;

    if (!axios) {
        throw new Error('[vue-auth-image] cannot locate Axios');
    }

    function setImgSrc(el, binding) {
        if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
            var imageUrl = binding.value;
            axios({
                method: 'get',
                url: imageUrl,
                responseType: 'arraybuffer',
                headers: {...axios.defaults.headers.common, ...{
                        'Accept' : "image/jpeg",
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache',
                        'Expires': '0'
                }},
            }).then(function (resp) {
                    var mimeType = resp.headers['content-type'].toLowerCase();
                    var imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
                    el.src = 'data:' + mimeType + ';base64,' + imgBase64;
                }).catch((function () {
                el.src = imageUrl;
            }));
        }
    }

    /*function setHrefImgSrc(el, binding) {
        if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
            var imageUrl = binding.value;
            axios({
                method: 'get',
                url: imageUrl,
                responseType: 'arraybuffer'
            })
                .then(function (resp) {
                    var mimeType = resp.headers['content-type'].toLowerCase();
                    var imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
                    el.href = 'data:' + mimeType + ';base64,' + imgBase64;
                }).catch((function () {
                el.href = imageUrl;
            }));
        }
    }*/

    vueAuthImage.install = function (Vue) {
        Vue.directive('auth-src', {
            bind: function (el, binding) {
                setImgSrc(el, binding);
            },
            componentUpdated: function (el, binding) {
                setImgSrc(el, binding);
            }
        });
        /*Vue.directive('auth-src-href', {
            bind: function (el, binding) {
                setHrefImgSrc(el, binding);
            },
            componentUpdated: function (el, binding) {
                setHrefImgSrc(el, binding);
            }
        });*/

    };

    module.exports = vueAuthImage;
})();
