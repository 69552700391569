import axios from 'axios';
import {doLogin, doRefresh, doLogout} from '@/shared/dataservice';
import {log} from '@/shared/utils';
import store from "@/store"
import router from '@/router';

import {MESSAGE_TYPE_ERROR} from './consts';

import lang from './lang';

class Authentication {

    constructor() {
        log(["Construct Auth"]);
    }

    async login (user) {

        let loginResult = await doLogin(user);

        log(["login result", loginResult]);

        return this.handleAuthenticationResponse(loginResult, true);
    }

    async refresh () {

        let refreshResult = await doRefresh();

        log(["refresh result", refreshResult]);

        return this.handleAuthenticationResponse(refreshResult, false);
    }

    handleAuthenticationResponse (result, isLogin) {

        log(["Handle AUTH result", result]);

        if( parseInt(result.status) === 200 )
        {
            log(["Auth SUCCESS"]);

            this.setAuth(result.data.data.token, result.data.data.isAdmin, result.data.data.isSysAdmin);

            store.dispatch('setUserAction', {
                'name' : result.data.data.name,
                'isAdmin' : this.isAdmin()
            });

            return true;
        }
        else if( parseInt(result.status) === 401 ){

            if(isLogin){
                store.dispatch('addSystemMessageAction', {
                    'type' : MESSAGE_TYPE_ERROR,
                    'message' : lang.message_login_fail
                });
            }

            return false;
        }
        else
        {
            log(["Generic Auth FAIL"]);
            router.push( { name: 'Maintenance'} ).catch(() => {});
            return false;
        }
    }

    async logout () {
        log(["Logout"]);

        let logoutResult = await doLogout();

        //log(["logout result", logoutResult]);

        if( parseInt(logoutResult.status) === 204 ) {

            this.resetAuth();

            store.dispatch('setUserAction', {
                'name': null,
                'isAdmin': null
            });

            return true;
        }
        else
        {
            //log(["Generic Logout FAIL"]);

            return false;
        }
    }

    get token () {
        return this._token;
    }

    setAuth(token, isAdmin, isSysAdmin) {
        //log(["set auth", token, isAdmin, isSysAdmin]);

        isAdmin = (typeof isAdmin === "boolean") ? isAdmin : (isAdmin === 'true');
        isSysAdmin = (typeof isSysAdmin === "boolean") ? isSysAdmin : (isSysAdmin === 'true');

        this._token = token;
        this._isAdmin = isAdmin;
        this._isSysAdmin = isSysAdmin;

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    resetAuth () {
        //log(["Reset Auth"]);

        this._token = null;
        this._isAdmin = false;
        this._isSysAdmin = false;

        axios.defaults.headers.common['Authorization'] = null;
    }

    async isLoggedIn () {
        //log(["calling isLoggedIn", this._token]);

        // is user loggedin?
        if(this._token) {
            //log(["user IS isLoggedIn"]);

            return true;
        }
        // can we revive a session
        else if(await this.refresh()){
            //log(["user HAS refreshed"]);

            return true;
        }
        else
        {
            //log(["user NOT isLoggedIn"]);

            return false;
        }
    }

    isAdmin () {
        return (this._isAdmin || this._isSysAdmin);
    }

}

export default new Authentication();