<template>
    <div class="form-input" :class="[classString, buttonClassString, {'error': error}, {'locked': element.locked === true, 'unlocked' : element.locked === false}]">
        <span v-if="element.isTemplateContentEdit" class="padlock-wrapper"><label :for="element.name"><Label :label="element.label" :tooltip="element.tooltip"/></label><Lock :locked="element.locked" @toggleLocked="toggleLockedState($event)" /></span>
        <label v-else :for="element.name" class="lock"><Label :label="element.label" :tooltip="element.tooltip"/></label>

        <VueTrix v-model="inputValue" :inputId="element.name" :placeholder="element.placeholder"
                 :disabledEditor="element.disabled || element.readonly" @trix-focus="onInputFocusStandard" @trix-blur="onInputBlurStandard"/>
        <span class="error-message" v-if="error">{{error}}</span>
    </div>
</template>

<script>
    import VueTrix from "vue-trix";
    import Trix from "trix";

    import {log} from "@/shared/utils";

    import {formElementMixin} from "@/components/mixins/formelement";

    import Label from "@/components/form/label";
    import Lock from "@/components/form/lock";

    export default {
        name: "Formelement_wysiwyg",
        components: {Label, Lock, VueTrix},
        mixins: [formElementMixin],
        props: {
            element: {
                type: Object
            },
            value: {
                type: String
            },
            classString: {
                type: String
            }
        },
        computed: {
            inputValue: {
                get() {
                    return this.value
                },
                set(inputValue) {
                    this.$emit('input', inputValue)
                }
            },
            numRows() {
                return this.element.rows ? this.element.rows : 4;
            },
            buttonClassString() {
                log(["WYSIWYG element", this.element]);

                let myString = "";

                if (this.element.config.buttons.length) {
                    this.element.config.buttons.forEach(button => myString += " trix-allow-" + button);
                }

                return myString;
            }
        },
        mounted() {
            Trix.config.blockAttributes.default.tagName = 'p';

            Trix.config.blockAttributes.heading2 = {
                tagName: 'h2',
                terminal: true,
                breakOnReturn: true,
                group: false
            }

            addEventListener("trix-initialize", event => {
                const {toolbarElement} = event.target

                if(!toolbarElement.querySelector('[data-trix-attribute="heading2"]')){
                    const h1Button = toolbarElement.querySelector('[data-trix-attribute="heading1"]');
                    h1Button.insertAdjacentHTML("afterend", `<button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading2" title="Heading 2" tabindex="-1" data-trix-active="">H2</button>`)
                }
            });
        },
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        trix-toolbar .trix-button-group:not(.trix-button-group--history-tools) {
            display: none;

            button {
                display: none;
            }
        }

        trix-toolbar .trix-dialog__link-fields .trix-button-group{
            display: flex;
        }

        .trix-content h2 {
            font-size: 1.1em;
            line-height: 1.2;
            margin: 0;
        }
    }

    .trix-allow-b {
        ::v-deep {
            trix-toolbar .trix-button-group.trix-button-group--text-tools {
                display: block;

                button[data-trix-attribute=bold] {
                    display: block;
                }
            }
        }
    }

    .trix-allow-i {
        ::v-deep {
            trix-toolbar .trix-button-group.trix-button-group--text-tools {
                display: block;

                button[data-trix-attribute=italic] {
                    display: block;
                }
            }
        }
    }

    .trix-allow-a {
        ::v-deep {
            trix-toolbar .trix-button-group.trix-button-group--text-tools {
                display: block;

                button[data-trix-attribute=href] {
                    display: block;
                }
            }
        }
    }

    .trix-allow-h {
        ::v-deep {
            trix-toolbar .trix-button-group.trix-button-group--block-tools {
                display: block;

                button[data-trix-attribute=heading2] {
                    display: block;
                }
            }
        }
    }

    .trix-allow-ul {
        ::v-deep {
            trix-toolbar .trix-button-group.trix-button-group--block-tools {
                display: block;

                button[data-trix-attribute=bullet] {
                    display: block;
                }
            }
        }
    }

    .trix-allow-ol {
        ::v-deep {
            trix-toolbar .trix-button-group.trix-button-group--block-tools {
                display: block;

                button[data-trix-attribute=number] {
                    display: block;
                }
            }
        }
    }
</style>