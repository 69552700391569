// define a mixin object
import {log} from "@/shared/utils";

export const formElementPickerMixin = {
    data() {
        return {
            showOptions: false,
            selectedOptions : [],
            maxLabelLength : 120,
        }
    },
    computed: {
        ignoreOptions() {
            return this.element.ignoreOptions;
        },
        labelText() {
            let labelTextStr = this.element.label ? this.element.label : "";
            if(this.selectedOptions.length > 0 && !this.ignoreOptions){
                labelTextStr += ` (${this.selectedOptions.length} ${this.text.selected.toLowerCase()})`;
            }
            return labelTextStr;
        },
        getSelectedOptionElements(){
            return this.element.options.filter(function(u) {
                return u.selected;
            })
        }
    },
    methods: {
        toggleAccordion(e) {
            e.preventDefault();

            this.showOptions = !this.showOptions;
        },
        toggleIgnoreOptions(e){
            e.preventDefault();

            this.element.ignoreOptions = !this.element.ignoreOptions;
        },

        onInputFocusSelectedOption() {
            this.onInputFocus(this.selectedOption);
        },
        onInputBlurSelectedOption() {
            this.onInputBlur(this.selectedOption);
        },

        onInputFocusSelectedOptions() {
            this.onInputFocus(this.selectedOptions);
        },
        onInputBlurSelectedOptions() {
            this.onInputBlur(this.selectedOptions);
        },
        getOptionLabel(name, text) {
            log(["Getting option label", name, text]);

            let returnString = name ? "[" + name + "] " + text : text;
            return returnString.length > this.maxLabelLength ? (returnString).substring(0, 120) + "..." : returnString;
        }
    },
    watch:{
        selectedOptions: {
            handler: function() {
                log(["Syncing value with selected options"]);
                this.element.selectedOptions = this.selectedOptions;
            },
            deep: true
        }
    },
    created() {
        log(["Picker created", this.element, this.element.selectedOptions ]);
        this.selectedOptions = this.element.selectedOptions !== undefined ? this.element.selectedOptions : [];
    }

}