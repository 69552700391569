<template>
    <div class="form-input options options-select" :class="[classString, {'error': error}, {'locked': element.locked === true, 'unlocked' : element.locked === false}]"> <!-- locked or nothing -->
        <label :for="element.name" class="lock"><Label :label="labelText" :tooltip="element.tooltip" /></label>
        <input autocomplete="off" type="text" :id="element.name" :placeholder="element.placeholder" v-model="inputValue" :disabled="element.disabled" :readonly="element.readonly" >
        <span class="error-message" v-if="error">{{error}}</span>
    </div>
</template>

<script>
    import lang from '@/shared/lang';

    import Label from "@/components/form/label";

    import {formElementMixin} from "@/components/mixins/formelement";
    import {formElementPickerMixin} from "@/components/mixins/formelement_picker";

    export default {
        name: "Formelement_textpicker_value",
        components: {Label},
        mixins: [formElementMixin, formElementPickerMixin],
        props: {
            element: {
                type: Object
            },
            value: {
                type: [String, Array]
            },
            classString: {
                type: String
            }
        },
        computed: {
            text() {
                return lang;
            },
            // Overwriting method in formElementPickerMixin
            labelText() {
                return this.element.label ? this.element.label : "";
            },
            inputValue: {
                get() { return this.value },
                set(inputValue) {this.$emit('input', inputValue)}
            },
        },
    }
</script>