<template>
    <fieldset>
        <Formelement v-for="element in formData" :key="element.id" :elementData="element" v-model="element.value" :ref="'element-' + element.id" @user-input="onUserInput" />
    </fieldset>
</template>

<script>
    import {log} from "@/shared/utils";

    import Formelement from "./formelement";

    export default {
        name: "Form",
        components: {Formelement},
        props: {
            value: {
                type: Array,
            }
        },
        data() {
            return {
                formData: [],
                userTriggeredChange: false,
            }
        },
        created() {
            this.refreshFormData();
        },
        methods: {
            refreshFormData() {
                this.formData = this.value;
                log(["Refreshing form data", this.value, this.formData]);
            },
            save() {
                log(["Save triggered in form"]);

                // if pass validation, save local data back to main data
                if(this.validateForm()){
                    this.value = this.formData;
                    return true;
                }
                else
                {
                    return false;
                }

            },
            validateForm() {
                log(["Validate form"]);

                let pass = true;

                this.formData.forEach(element => {
                    //log("Validating" + element);
                    if( !this.validateFormElement(element.id) ){
                        pass = false;
                    }
                    log("Current pass state: " + pass);
                });

                return pass;
            },
            validateFormElement(elementID){
                return this.$refs['element-' + elementID][0].validateElement();
            },
            clear() {
                this.formData.forEach(element => {
                    log(["Resetting form element", element, this.$refs['element-' + element.id]]);
                    this.$refs['element-' + element.id][0].clearElement();
                });
            },
            onUserInput(){
                log("Form detects user has made change");
                if(!this.userTriggeredChange){
                    log("This is the first change since load/save");
                    this.userTriggeredChange = true;
                    this.$emit('changed');
                }
            },
            resetChanged(){
                log("Reset form changed state");
                this.userTriggeredChange = false;
            },
            hasChanged(){
                return this.userTriggeredChange;
            }
        },
        watch: {
            value: {
                handler(newVal) {
                    log(["Form emit", newVal]);
                    this.refreshFormData();
                    this.$emit('input', newVal)
                },
                deep: true
            }
        }
    }
</script>