import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import Error404 from '@/views/Error404.vue';
import Maintenance from '@/views/Maintenance.vue';

import Auth from '@/shared/authentication';
import store from "@/store"
import {MESSAGE_TYPE_ERROR} from "@/shared/consts";
import lang from "@/shared/lang";
import {log} from "@/shared/utils";

// TODO: Delete this
import All from '@/All.vue';


Vue.use(VueRouter);

/*

TAKE NOTE OF THIS FOR AUTHENTICATION
https://blog.sqreen.com/authentication-best-practices-vue/

but also this which has a slightly better (meta) approach?
https://router.vuejs.org/guide/advanced/meta.html

*/
const routes = [
    {
        path: '/home',
        name: 'Home',
        beforeEnter: (to, from, next) => {
            log(["Redirecting to appropriate home", Auth.isAdmin()]);

            if(Auth.isAdmin()){
                next({ name: 'AdminHome', replace: true });
            }
            else
            {
                next({ name: 'UserHome', replace: true });
            }
        }
    },
    /**
     * USER
     */
    {
        path: '/user',
        redirect: '/user/home',
        name: 'User',
        component: () => import(/* webpackChunkName: "userbase" */ '../views/user/_UserWrapper.vue'),
        meta: {
            metaTitle: 'Home',
            requiresAuth: true,
            isUserContent: true,
        },
        children: [
            {
                path: 'home/:message?',
                name: 'UserHome',
                component: () => import(/* webpackChunkName: "userbase" */ '../views/user/templates/Home.vue'),
                meta: {
                    pageTitle: 'Welcome %s%'
                }
            },
            {
                path: 'adverts',
                redirect: 'adverts/list',
                name: 'UserAdverts',
                component: () => import(/* webpackChunkName: "useradverts" */ '../views/user/templates/_AdvertsWrapper.vue'),
                meta: {
                    metaTitle: 'Adverts',
                    pageTitle: 'Adverts',
                },
                children: [
                    {
                        path: 'list',
                        name: 'UserAdvertsList',
                        component: () => import(/* webpackChunkName: "useradverts" */ '../views/user/templates/AdvertsList.vue'),
                        meta: {
                            metaTitle: 'Your adverts',
                            pageTitle: 'Your adverts',
                        },
                    },
                    {
                        path: 'archive',
                        name: 'UserAdvertsArchive',
                        component: () => import(/* webpackChunkName: "useradverts" */ '../views/user/templates/AdvertsArchive.vue'),
                        meta: {
                            metaTitle: 'Your advert archive',
                            pageTitle: 'Your advert archive',
                            breadcrumbTitle: 'Archive',
                        },
                    },
                    {
                        path: 'new',
                        name: 'UserAdvertsNew',
                        component: () => import(/* webpackChunkName: "sharedadverts" */ '../views/shared/templates/AdvertsNew.vue'),
                        meta: {
                            metaTitle: 'Create a new advert',
                            pageTitle: 'Create new advert - select template',
                            breadcrumbTitle: 'New',
                        },
                    },
                    {
                        path: 'edit/:id?/:message?/:templateid?',
                        name: 'UserAdvertsEdit',
                        component: () => import(/* webpackChunkName: "sharedadverts" */ '../views/shared/templates/AdvertsEdit.vue'),
                        meta: {
                            metaTitle: 'Edit advert',
                            pageTitle: '%s% advert',
                            breadcrumbTitle: 'Edit',
                        },
                    },
                    {
                        path: 'view/:id?',
                        name: 'UserAdvertsView',
                        component: () => import(/* webpackChunkName: "sharedadverts" */ '../views/shared/templates/AdvertsView.vue'),
                        meta: {
                            metaTitle: 'Review advert',
                            pageTitle: 'Review: %s%',
                            breadcrumbTitle: 'Review',
                        },
                    },
                    {
                        path: 'export/:id?',
                        name: 'UserAdvertsExport',
                        component: () => import(/* webpackChunkName: "sharedadverts" */ '../views/shared/templates/AdvertsExport.vue'),
                        meta: {
                            metaTitle: 'Export advert',
                            pageTitle: 'Export advert',
                            breadcrumbTitle: 'Export',
                        },
                    },
                ]
            },
            {
                path: 'messages',
                redirect: 'messages/list',
                name: 'UserMessages',
                component: () => import(/* webpackChunkName: "usermessages" */ '../views/user/templates/_MessagesWrapper.vue'),
                meta: {
                    breadcrumbTitle: 'Messages',
                },
                children: [
                    {
                        path: 'list',
                        name: 'UserMessagesList',
                        component: () => import(/* webpackChunkName: "usermessages" */ '../views/user/templates/MessagesList.vue'),
                        meta: {
                            metaTitle: 'Messages',
                            pageTitle: 'Messages',
                            breadcrumbTitle: 'List',
                        },
                    },
                    {
                        path: 'view/:id?',
                        name: 'UserMessagesView',
                        component: () => import(/* webpackChunkName: "usermessages" */ '../views/user/templates/MessagesView.vue'),
                        meta: {
                            metaTitle: 'View message',
                            pageTitle: '%s%',
                            breadcrumbTitle: 'View',
                        },
                    },
                ]
            },
            {
                path: 'faqs',
                name: 'UserFAQs',
                component: () => import(/* webpackChunkName: "userfaqs" */ '../views/user/templates/FAQs.vue'),
                meta: {
                    metaTitle: 'FAQs',
                    pageTitle: 'FAQs',
                },
            },
        ]
    },
    /**
     * ADMIN
     */
    {
        path: '/admin',
        redirect: '/admin/home',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "adminbase" */ '../views/admin/_AdminWrapper.vue'),
        meta: {
            metaTitle: 'Home',
            requiresAuth: true,
            isAdminContent: true,
        },
        children: [
            {
                path: 'home',
                name: 'AdminHome',
                component: () => import(/* webpackChunkName: "adminbase" */ '../views/admin/templates/Home.vue'),
                meta: {
                    pageTitle: 'Welcome %s%'
                }
            },
            {
                path: 'templates',
                redirect: 'templates/list',
                name: 'AdminTemplates',
                component: () => import(/* webpackChunkName: "admintemplates" */ '../views/admin/templates/_TemplateWrapper.vue'),
                meta: {
                    metaTitle: 'Templates',
                    pageTitle: 'Templates',
                },
                children: [
                    {
                        path: 'list',
                        name: 'AdminTemplateList',
                        component: () => import(/* webpackChunkName: "admintemplates" */ '../views/admin/templates/TemplateList.vue'),
                        meta: {
                            metaTitle: 'Templates Lists',
                            pageTitle: 'Your Templates',
                            breadcrumbTitle: 'List',
                        },
                    },
                    {
                        path: 'add',
                        name: 'AdminTemplateNew',
                        redirect: { name: 'AdminTemplateEdit', params : { id : 0 } }
                    },
                    {
                        path: 'archive',
                        name: 'AdminTemplateArchive',
                        component: () => import(/* webpackChunkName: "admintemplates" */ '../views/admin/templates/TemplateArchive.vue'),
                        meta: {
                            metaTitle: 'Template Archive',
                            pageTitle: 'Template Archive',
                            breadcrumbTitle: 'Archive',
                        },
                    },
                    {
                        path: 'edit/:id/:message?',
                        name: 'AdminTemplateEdit',
                        component: () => import(/* webpackChunkName: "admintemplates" */ '../views/admin/templates/TemplateEdit.vue'),
                        meta: {
                            metaTitle: 'Edit Template',
                            pageTitle: '%s% Template',
                            breadcrumbTitle: 'Edit',
                        },
                    },
                    {
                        path: 'editcontent/:id',
                        name: 'AdminTemplateEditContent',
                        component: () => import(/* webpackChunkName: "admintemplates" */ '../views/admin/templates/TemplateEditContent.vue'),
                        meta: {
                            metaTitle: 'Edit template content',
                            pageTitle: 'Edit default content: %s%',
                            breadcrumbTitle: 'Edit Content',
                        },
                    },
                    {
                        path: 'review/:id/:state?',
                        name: 'AdminTemplateView',
                        component: () => import(/* webpackChunkName: "admintemplates" */ '../views/admin/templates/TemplateView.vue'),
                        meta: {
                            metaTitle: 'Review template',
                            pageTitle: 'Review template: %s%',
                            breadcrumbTitle: 'Review',
                        },
                    },
                ]
            },
            {
                path: 'adverts',
                redirect: 'adverts/list',
                name: 'AdminAdverts',
                component: () => import(/* webpackChunkName: "adminadverts" */ '../views/admin/templates/_AdvertsWrapper.vue'),
                meta: {
                    breadcrumbTitle: 'Adverts',
                },
                children: [
                    {
                        path: 'list/:businessUnit?',
                        name: 'AdminAdvertsList',
                        component: () => import(/* webpackChunkName: "adminadverts" */ '../views/admin/templates/AdvertsList.vue'),
                        meta: {
                            metaTitle: 'Adverts',
                            pageTitle: 'Adverts',
                            breadcrumbTitle: 'List',
                        },
                    },
                    {
                        path: 'archive',
                        name: 'AdminAdvertsArchive',
                        component: () => import(/* webpackChunkName: "adminadverts" */ '../views/admin/templates/AdvertsArchive.vue'),
                        meta: {
                            metaTitle: 'Advert archive',
                            pageTitle: 'Advert archive',
                            breadcrumbTitle: 'Archive',
                        },
                    },
                    {
                        path: 'new',
                        name: 'AdminAdvertsNew',
                        component: () => import(/* webpackChunkName: "sharedadverts" */ '../views/shared/templates/AdvertsNew.vue'),
                        meta: {
                            metaTitle: 'Create a new advert',
                            pageTitle: 'Create new advert - select template',
                            breadcrumbTitle: 'New',
                        },
                    },
                    {
                        path: 'edit/:id?/:message?/:templateid?',
                        name: 'AdminAdvertsEdit',
                        component: () => import(/* webpackChunkName: "sharedadverts" */ '../views/shared/templates/AdvertsEdit.vue'),
                        meta: {
                            metaTitle: 'Edit advert',
                            pageTitle: '%s% advert',
                            breadcrumbTitle: 'Edit',
                        },
                    },
                    {
                        path: 'view/:id?',
                        name: 'AdminAdvertsView',
                        component: () => import(/* webpackChunkName: "sharedadverts" */ '../views/shared/templates/AdvertsView.vue'),
                        meta: {
                            metaTitle: 'Review advert',
                            pageTitle: 'Review: %s%',
                            breadcrumbTitle: 'Review',
                        },
                    },
                    {
                        path: 'export/:id?',
                        name: 'AdminAdvertsExport',
                        component: () => import(/* webpackChunkName: "sharedadverts" */ '../views/shared/templates/AdvertsExport.vue'),
                        meta: {
                            metaTitle: 'Export advert',
                            pageTitle: 'Export advert',
                            breadcrumbTitle: 'Export',
                        },
                    },
                ]
            },
            {
                path: 'libraries',
                redirect: 'libraries/list',
                name: 'AdminMediaGroup',
                component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/_MediaGroupWrapper.vue'),
                meta: {
                    breadcrumbTitle: 'Libraries',
                },
                children: [
                    {
                        path: 'list',
                        name: 'AdminMediaGroupList',
                        component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/MediaGroupList.vue'),
                        meta: {
                            metaTitle: 'Image Libraries',
                            pageTitle: 'Image Libraries',
                            breadcrumbTitle: 'List',
                        },
                    },
                    {
                        path: 'add',
                        name: 'AdminMediaGroupNew',
                        component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/MediaGroupNew.vue'),
                        meta: {
                            metaTitle: 'Add image library',
                            pageTitle: 'Create new image library',
                            breadcrumbTitle: 'Add',
                        },
                    },
                    {
                        path: 'library/:mediaGroupID',
                        redirect: 'library/:mediaGroupID/list',
                        name: 'AdminMediaGroupMedia',
                        component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/_MediaGroupMediaWrapper.vue'),
                        meta: {
                            breadcrumbTitle: '%mediaGroupName%',
                        },
                        children: [
                            {
                                path: 'list',
                                name: 'AdminMediaGroupMediaList',
                                component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/MediaGroupMediaList.vue'),
                                meta: {
                                    metaTitle: 'View Library Images',
                                    pageTitle: '%s%',
                                    breadcrumbTitle: 'List',
                                },
                            },
                            {
                                path: 'archive',
                                name: 'AdminMediaGroupMediaArchive',
                                component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/MediaGroupMediaArchive.vue'),
                                meta: {
                                    metaTitle: 'Archived Library Images',
                                    pageTitle: '%s% (archived images)',
                                    breadcrumbTitle: 'Archived Images',
                                },
                            },
                            {
                                path: 'image/:mediaItemID',
                                name: 'AdminMediaGroupMediaView',
                                component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/MediaGroupMediaView.vue'),
                                meta: {
                                    metaTitle: 'View Image',
                                    pageTitle: '%s%',
                                    breadcrumbTitle: 'View image',
                                },
                            },
                            {
                                path: 'image/:mediaItemID/edit',
                                name: 'AdminMediaGroupMediaEdit',
                                component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/MediaGroupMediaEdit.vue'),
                                meta: {
                                    metaTitle: 'Edit Image',
                                    pageTitle: '%s%',
                                    breadcrumbTitle: 'Edit image',
                                },
                            },
                            {
                                path: 'add',
                                name: 'AdminMediaGroupMediaNew',
                                component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/MediaGroupMediaNew.vue'),
                                meta: {
                                    metaTitle: 'Add new Image',
                                    pageTitle: 'Add new Image',
                                    breadcrumbTitle: 'Add image',
                                },
                            },
                        ]
                    },
                    {
                        path: 'archive',
                        redirect: 'archive/list',
                        name: 'AdminMediaGroupArchive',
                        component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/_MediaGroupArchiveWrapper.vue'),
                        meta: {
                            breadcrumbTitle: 'Archive',
                        },
                        children: [
                            {
                                path: 'list',
                                name: 'AdminMediaGroupArchiveList',
                                component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/MediaGroupArchive.vue'),
                                meta: {
                                    metaTitle: 'Image Library Archive',
                                    pageTitle: 'Image Library Archive',
                                    breadcrumbTitle: 'List',
                                }
                            },
                            {
                                path: 'library/:mediaGroupID',
                                redirect: 'library/:mediaGroupID/list',
                                name: 'AdminMediaGroupArchiveMedia',
                                component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/_MediaGroupMediaArchiveWrapper.vue'),
                                meta: {
                                    breadcrumbTitle: '%mediaGroupName%',
                                },
                                children: [
                                    {
                                        path: 'list',
                                        name: 'AdminMediaGroupArchiveMediaList',
                                        component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/MediaGroupArchiveMediaList.vue'),
                                        meta: {
                                            metaTitle: 'View Library Images',
                                            pageTitle: '%s%',
                                            breadcrumbTitle: 'List',
                                        },
                                    },
                                    {
                                        path: 'image/:mediaItemID',
                                        name: 'AdminMediaGroupArchiveMediaView',
                                        component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/MediaGroupMediaView.vue'),
                                        meta: {
                                            metaTitle: 'View Image',
                                            pageTitle: '%s%',
                                            breadcrumbTitle: 'View image',
                                        },
                                    },
                                ]
                            }
                        ],
                    },
                ]
            },
            {
                path: 'reports',
                name: 'AdminReporting',
                component: () => import(/* webpackChunkName: "adminreports" */ '../views/admin/templates/Reporting.vue'),
                meta: {
                    metaTitle: 'Reporting',
                    pageTitle: 'Reporting',
                    breadcrumbTitle: 'Reporting',
                },
            },
            {
                path: 'messages',
                redirect: 'messages/list',
                name: 'AdminMessages',
                component: () => import(/* webpackChunkName: "adminmessages" */ '../views/admin/templates/_MessagesWrapper.vue'),
                meta: {
                    breadcrumbTitle: 'Messages',
                },
                children: [
                    {
                        path: 'list/:message?',
                        name: 'AdminMessagesList',
                        component: () => import(/* webpackChunkName: "adminmessages" */ '../views/admin/templates/MessagesList.vue'),
                        meta: {
                            metaTitle: 'Messages',
                            pageTitle: 'Messages',
                            breadcrumbTitle: 'List',
                        },
                    },
                    {
                        path: 'edit/:id?/:message?',
                        name: 'AdminMessagesEdit',
                        component: () => import(/* webpackChunkName: "adminmessages" */ '../views/admin/templates/MessagesEdit.vue'),
                        meta: {
                            metaTitle: 'Edit message',
                            pageTitle: 'Edit: %s%',
                            breadcrumbTitle: 'Edit',
                        },
                    },
                    {
                        path: 'view/:id?',
                        name: 'AdminMessagesView',
                        component: () => import(/* webpackChunkName: "adminmessages" */ '../views/admin/templates/MessagesView.vue'),
                        meta: {
                            metaTitle: 'View message',
                            pageTitle: '%s%',
                            breadcrumbTitle: 'View',
                        },
                    },
                    {
                        path: 'add',
                        name: 'AdminMessagesNew',
                        component: () => import(/* webpackChunkName: "adminmessages" */ '../views/admin/templates/MessagesNew.vue'),
                        meta: {
                            metaTitle: 'Add message',
                            pageTitle: 'Create a new message',
                            breadcrumbTitle: 'Add',
                        },
                    },
                    {
                        path: 'deleted',
                        name: 'AdminMessagesArchive',
                        component: () => import(/* webpackChunkName: "usermessages" */ '../views/admin/templates/MessagesArchive.vue'),
                        meta: {
                            metaTitle: 'Deleted Messages',
                            pageTitle: 'Deleted Messages',
                            breadcrumbTitle: 'Deleted',
                        },
                    },
                ]
            },
            {
                path: 'system',
                redirect: 'system/users',
                name: 'AdminSystem',
                component: () => import(/* webpackChunkName: "adminsystem" */ '../views/admin/templates/_SystemWrapper.vue'),
                meta: {
                    metaTitle: 'System',
                    pageTitle: 'System',
                },
                children: [
                    {
                        path: 'users',
                        redirect: 'users/list',
                        name: 'AdminUser',
                        meta: {
                            breadcrumbTitle: 'User',
                        },
                        component: () => import(/* webpackChunkName: "adminsystem" */ '../views/admin/templates/_UserWrapper.vue'),
                        children: [
                            {
                                path: 'list/:message?',
                                name: 'AdminUserList',
                                component: () => import(/* webpackChunkName: "adminsystem" */ '../views/admin/templates/UserList.vue'),
                                meta: {
                                    metaTitle: 'User list',
                                    pageTitle: 'Users',
                                    breadcrumbTitle: 'List',
                                }
                            },
                            {
                                path: 'edit/:id?',
                                name: 'AdminUserEdit',
                                component: () => import(/* webpackChunkName: "adminsystem" */ '../views/admin/templates/UserEdit.vue'),
                                meta: {
                                    metaTitle: 'Edit User',
                                    pageTitle: 'Edit User',
                                    breadcrumbTitle: 'Edit'
                                },
                            },
                            {
                                path: 'add',
                                name: 'AdminUserNew',
                                component: () => import(/* webpackChunkName: "adminsystem" */ '../views/admin/templates/UserNew.vue'),
                                meta: {
                                    metaTitle: 'Add User',
                                    pageTitle: 'Add User',
                                    breadcrumbTitle: 'Add'
                                },
                            },
                        ]
                    },
                    {
                        path: 'departments',
                        redirect: 'departments/list',
                        name: 'AdminBusinessUnit',
                        meta: {
                            breadcrumbTitle: 'Departments',
                        },
                        component: () => import(/* webpackChunkName: "adminsystem" */ '../views/admin/templates/_BusinessUnitWrapper.vue'),
                        children: [
                            {
                                path: 'list/:message?',
                                name: 'AdminBusinessUnitList',
                                component: () => import(/* webpackChunkName: "adminsystem" */ '../views/admin/templates/BusinessUnitList.vue'),
                                meta: {
                                    metaTitle: 'Departments list',
                                    pageTitle: 'Departments',
                                    breadcrumbTitle: 'List',
                                }
                            },
                            {
                                path: 'edit/:id?',
                                name: 'AdminBusinessUnitEdit',
                                component: () => import(/* webpackChunkName: "adminsystem" */ '../views/admin/templates/BusinessUnitEdit.vue'),
                                meta: {
                                    metaTitle: 'Edit Department',
                                    pageTitle: 'Edit Department',
                                    breadcrumbTitle: 'Edit'
                                },
                            },
                            {
                                path: 'add',
                                name: 'AdminBusinessUnitNew',
                                component: () => import(/* webpackChunkName: "adminsystem" */ '../views/admin/templates/BusinessUnitNew.vue'),
                                meta: {
                                    metaTitle: 'Add Department',
                                    pageTitle: 'Add Department',
                                    breadcrumbTitle: 'Add'
                                },
                            }
                        ]
                    },
                    {
                        path: 'faqs',
                        name: 'AdminFAQs',
                        component: () => import(/* webpackChunkName: "adminsystem" */ '../views/admin/templates/FAQs.vue'),
                        meta: {
                            metaTitle: 'FAQs',
                            pageTitle: 'FAQs',
                        },
                    },
                    {
                        path: 'inpagehelp',
                        redirect: 'inpagehelp/list',
                        name: 'AdminInPageHelp',
                        component: () => import(/* webpackChunkName: "adminsystem" */ '../views/admin/templates/_InPageHelpWrapper.vue'),
                        meta: {
                            breadcrumbTitle: 'In page help',
                        },
                        children: [
                            {
                                path: 'list/:message?',
                                name: 'AdminInPageHelpList',
                                component: () => import(/* webpackChunkName: "adminsystem" */ '../views/admin/templates/InPageHelpList.vue'),
                                meta: {
                                    metaTitle: 'In page help list',
                                    pageTitle: 'In page help',
                                    breadcrumbTitle: 'List',
                                }
                            },
                            {
                                path: 'edit/:id?',
                                name: 'AdminInPageHelpEdit',
                                component: () => import(/* webpackChunkName: "adminsystem" */ '../views/admin/templates/InPageHelpEdit.vue'),
                                meta: {
                                    metaTitle: 'Edit in page help',
                                    pageTitle: 'Edit in page help text: %s%',
                                    breadcrumbTitle: 'Edit'
                                },
                            }
                        ]
                    }
                ]
            },
            {
                path: 'templatesettings',
                redirect: 'templatesettings/properties',
                name: 'AdminTemplateSettings',
                component: () => import(/* webpackChunkName: "adminsettings" */ '../views/admin/templates/_SettingsWrapper.vue'),
                meta: {
                    breadcrumbTitle: 'Settings',
                    metaTitle: 'Content Management',
                    pageTitle: 'Content Management',
                },
                children: [
                    {
                        path: 'properties',
                        name: 'AdminTemplateSchemaSettingsCollectionProperties',
                        meta: {
                            breadcrumbTitle: 'Template sections',
                            pageTitle: 'Template Sections',
                        },
                        component: () => import(/* webpackChunkName: "adminsettings" */ '../views/admin/templates/TemplateSchemaSettingsCollectionProperties.vue'),
                    },
                    {
                        path: 'contentoptions',
                        redirect: 'contentoptions/list',
                        name: 'AdminTemplateSchemaSettingsCollection',
                        component: () => import(/* webpackChunkName: "adminsettings" */ '../views/admin/templates/_TemplateSchemaSettingsCollectionWrapper.vue'),
                        meta: {
                            breadcrumbTitle: 'Content options',
                        },
                        children: [
                            {
                                path: 'list/:message?',
                                name: 'AdminTemplateSchemaSettingsCollectionList',
                                component: () => import(/* webpackChunkName: "adminsettings" */ '../views/admin/templates/TemplateSchemaSettingsCollectionList.vue'),
                                meta: {
                                    metaTitle: 'Content options list',
                                    pageTitle: 'Content options',
                                    breadcrumbTitle: 'List',
                                }
                            },
                            {
                                path: 'edit/:id?/:alias?',
                                name: 'AdminTemplateSchemaSettingsCollectionEdit',
                                component: () => import(/* webpackChunkName: "adminsettings" */ '../views/admin/templates/TemplateSchemaSettingsCollectionEdit.vue'),
                                meta: {
                                    metaTitle: 'Edit Content options',
                                    pageTitle: 'Edit %s% Content options',
                                    breadcrumbTitle: 'Edit'
                                },
                            }
                        ]
                    }
                ]
            }
        ],
    },
    {
        path: '/login/:action?',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Login',
        },
    },
    {
        path: '/logout',
        name: 'Logout',
        beforeEnter: (to, from, next) => {
            log(['Logout beforeEnter 1']);

            Auth.logout().then((response) => {

                if (response){
                    log(['pass LOGGED OUT beforeEnter', response]);

                    next({ name: 'Login', params: { 'action' : 'hasloggedout' }, replace: true });
                }
                else
                {
                    log(['Fail LOGGED OUT beforeEnter']);

                    store.dispatch('setSystemMessageAction', {
                        'type' : MESSAGE_TYPE_ERROR,
                        'message' : lang.message_logout_fail
                    });

                    next(false);
                }
            });
        }
    },
    {
        path: '',
        name: 'Root',
        beforeEnter: (to, from, next) => {
            log(["Redirecting to appropriate place", Auth.isAdmin(), Auth.isLoggedIn()]);

            Auth.isLoggedIn().then((response) => {

                log(["Is logged in response...", response]);
                if(response){
                    if(Auth.isAdmin()){
                        next({ name: 'AdminHome', replace: true });
                    }
                    else
                    {
                        next({ name: 'UserHome', replace: true });
                    }
                }
                else
                {
                    next({ name: 'Login', replace: true });
                }
            });
        }
    },
    {
        path: '/original/:mediaItemID',
        name: 'AdminMediaGroupMediaViewOriginal',
        component: () => import(/* webpackChunkName: "adminmedia" */ '../views/admin/templates/MediaGroupMediaViewOriginal.vue'),
        meta: {
            metaTitle: 'View Original Image',
            requiresAuth: true,
            isAdminContent: true,
        },
    },
    {
        path: '/welcome',
        name: 'ConfirmEmail',
        component: () => import(/* webpackChunkName: "welcome" */ '../views/ConfirmEmail.vue'),
        meta: {
            title: 'Welcome to Connex',
        },
    },
    {
        path: '/request-password-reset',
        name: 'PasswordReset',
        component: () => import(/* webpackChunkName: "password" */ '../views/PasswordReset.vue'),
        meta: {
            title: 'Password reset',
        },
    },
    {
        path: '/reset-confirmation',
        name: 'PasswordResetConfirmation',
        component: () => import(/* webpackChunkName: "password" */ '../views/PasswordResetConfirmation.vue'),
        meta: {
            title: 'Password reset',
        },
    },
    /**
     * TODO: DELETE THIS START
     */
    {
        path: '/htmldev',
        name: 'HTMLDev',
        component: All,
        meta: {
            title: 'HTMLDev',
        },
    }
    /**
     * TODO: DELETE THIS END
     */,
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        meta: {
            title: 'System maintenance',
        },
    },
    {
        path: '*',
        name: '404',
        component: Error404,
        meta: {
            title: 'Page not found (404)',
        },
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

/**
 * Handles authentication
 * Ensures user can view section and redirects to login if not
 */
router.beforeEach((to, from, next) => {

    // Reset any previous system messages
    store.dispatch('setSystemMessageAction', [] );

    // If a route with a title was found, set the document (page) title to that value.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTitle);
    if(nearestWithTitle) document.title = nearestWithTitle.meta.metaTitle + lang.meta_page_title_suffix;

    // Page title
    store.dispatch('setPageTitleAction', { title : to.meta.pageTitle });

    // Work out permissions
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.

        log(["Attemping to go to", to]);

        Auth.isLoggedIn().then((response) => {

            if (!response){
                next({ name: 'Login', replace: true, query : { returnUrl : to.path } });
            }
            else
            {
                // If this is admin content, and user doens't have admin rights redirect to user home
                if(to.matched.some(record => record.meta.isAdminContent) && !Auth.isAdmin()){
                    next({ name: 'UserHome', replace: true, params : { message : "notadmin" } });
                }
                else
                {
                    next();
                }
            }
        })

    } else {
        next() // make sure to always call next()!
    }

});
/*
router.afterEach((to) => {


});
*/
export default router;
