<template>
    <div class="root-element" v-if="systemMessages">
        <div class="system-message" v-for="(systemMessage, index) in systemMessages" :key="index" :class="systemMessage.type">
            <p>{{systemMessage.message}}</p>
        </div>
    </div>
</template>

<script>
// TODO: ADD AUTO SCROLL TO MESSAGE?

import {mapState} from "vuex";
import {log,localScrollToDomElement} from "@/shared/utils";

export default {
  name: "SystemMessage",
  components: {},
  props: {},
  computed: {
    ...mapState(['systemMessages']), // imports user from store
    systemMessages() {
      return this.$store.state.systemMessages;
    },
  },
  watch:{
    systemMessages()
    {
      log(["New system message", window.scrollY, (window.scrollY > 50), this.systemMessages.length]);
      if(this.systemMessages.length > 0 && window.scrollY > 50){
        let messageElements = document.getElementsByClassName('system-message');
        //log(["There are system messages", messageElements]);
        if(messageElements.length > 0){
          //log(["Target element", messageElements[messageElements.length - 1]]);
          localScrollToDomElement(messageElements[messageElements.length - 1]);
        }
      }
    }
  }
}
</script>