import Vue from 'vue'
import Vuex from 'vuex'
import {saveToLocalStorage, getFromLocalStorage} from '@/shared/dataservice';
import {log} from '@/shared/utils';

Vue.use(Vuex)

const state = {
    user : {},
    pageTitle : {
        title : "",
        isEditable : false
    },
    systemMessages : [],
    helpMessages: [],
    hiddenHelpMessages: [],
    breadCrumbs: [],
    section : "Default",
};
const mutations = {
    setUser(state, user){
        state.user = user;
    },
    setSystemMessage(state, message){
        state.systemMessages = message;
    },
    addSystemMessage(state, message){
        state.systemMessages.push(message);
    },
    setPageTitle(state, pageTitle){
        state.pageTitle = pageTitle;
    },
    setHelpMessageForRoute(state, data){
        state.helpMessages[data.routeName] = data.message;
    },
    setHiddenHelpMessageForRoute(state, routeName){
        state.hiddenHelpMessages.push(routeName);
    },
    setRestoreHelpMessageForRoute(state, routeName){
        state.hiddenHelpMessages.splice(state.hiddenHelpMessages.indexOf(routeName), 1);
    },
    setHiddenHelpMessages(state, hiddenMessages){
        state.hiddenHelpMessages = hiddenMessages;
    },
    setBreadcrumbs(state, breadCrumbs){
        state.breadCrumbs = breadCrumbs;
    },
    setSection(state, section){
        state.section = section;
    },
};

const actions = {
    setUserAction({ commit }, user) {
        commit('setUser', user);
    },
    setSystemMessageAction({ commit }, message) {
        commit('setSystemMessage', message);
    },
    addSystemMessageAction({ commit }, message) {
        commit('addSystemMessage', message);
    },
    setPageTitleAction({ commit }, {title, isEditable = false, editableTitle = "", changeSaved = false}, ){
        //log(["Setting page title action", title, isEditable, editableTitle, changeSaved]);

        commit('setPageTitle', {
            title : title,
            isEditable : isEditable,
            editableTitle : editableTitle,
            changeSaved : changeSaved
        });
    },
    setHelpMessageForRouteAction({ commit }, data) {
        //log(["Saving help message", data.routeName, data.message]);

        commit('setHelpMessageForRoute', data);
    },
    setHiddenHelpMessageForRouteAction({ commit }, routeName) {
        //log(["Hiding help message", routeName]);

        // only add it to the array if it doesn't already exist
        if(state.hiddenHelpMessages.indexOf(routeName) < 0){
            commit('setHiddenHelpMessageForRoute', routeName);

            // save it to local storage too
            saveToLocalStorage("hidden_help", state.hiddenHelpMessages.join(','));
        }
    },
    setRestoreHelpMessageForRouteAction({ commit }, routeName){
        //log(["Restoring help message", routeName]);

        // only remove it from the array if it does already exist
        if(state.hiddenHelpMessages.indexOf(routeName) > 0){
            commit('setRestoreHelpMessageForRoute', routeName);

            // save it to local storage too
            saveToLocalStorage("hidden_help", state.hiddenHelpMessages.join(','));
        }
    },
    setHiddenHelpMessagesAction({ commit }) {

        let hiddenMessages = getFromLocalStorage("hidden_help");

        //log(["Preloading all hidden messages from local storage", hiddenMessages]);

        if(hiddenMessages !== null){
            commit('setHiddenHelpMessages', hiddenMessages.split(','));
        }
    },
    setBreadCrumbsAction({ commit }, {route, replace = []}) {
        let myBreadCrumbs = [];

        log(["Breadcrumbs", route, replace]);

        route.forEach(element => {

            if(element.meta && (element.meta.metaTitle || element.meta.breadcrumbTitle)){
                let myTitle = element.meta.breadcrumbTitle ? element.meta.breadcrumbTitle : element.meta.metaTitle;

                if(replace.length > 0){
                    for(let i=0; i<replace.length; i++){
                        myTitle = myTitle.replace(replace[i].search, replace[i].replace)
                    }
                }

                myBreadCrumbs.push({
                    'title' : myTitle,
                    'route' : element.path,
                    'name'  : element.name
                });
            }
        }, myBreadCrumbs);

        commit('setBreadcrumbs', myBreadCrumbs);
    },
    setSectionAction({ commit }, section) {
        commit('setSection', section);
    },
};

const getters = {};


export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});
