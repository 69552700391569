export const API = process.env.VUE_APP_APP_API;
export const MEDIA_ROOT = process.env.VUE_APP_MEDIA_ROOT;

export const LOCAL_STORAGE_PREFIX = "calgaryconnex_";

export const MESSAGE_TYPE_ERROR = 'error';
export const MESSAGE_TYPE_SUCCESS = 'success';
export const MESSAGE_TYPE_WARNING = 'warning';

export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';
export const DISPLAY_TIME_FORMAT = 'HH:mm';
export const DATE_INPUT_FORMAT = 'YYYY-MM-DD';

export const TIMEZONE_OFFSET = '+00:00';

// eslint-disable-next-line
export const EMAIL_REGEX = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
export const PASSWORD_NUMBER_UNIQUE_CHARACTERS = 5;
// eslint-disable-next-line
export const PASSWORD_REGEX = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])");

export const PROPERTY_EDITORS = [
    {
        editorType : "Text",
    },
    {
        editorType : "TextPicker",
        optionsEditComponent : "TextPicker"
    },
    {
        editorType : "Textarea",
    },
    {
        editorType : "TextareaPicker",
        optionsEditComponent : "TextareaPicker"
    },
    {
        editorType : "MediaPicker",
    },
];

export const REPORT_CSV_MAX_ROWS = 10000;