<template>
    <div id="app">
        <Header />

        <main class="root-element">
            <Breadcrumbs />

            <h1>Page title</h1>

            <Help />

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Template Name</th>
                            <th>Department</th>
                            <th>Last modified</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><a href="#" title="" class="default-action">Graduate scheme</a></td>
                            <td>Motor claims</td>
                            <td>10:29 today</td>
                            <td>
                                <a href="#" title="">View</a>
                                <a href="#" title="">Edit</a>
                                <a href="#" title="">Copy</a>
                                <a href="#" title="">Archive</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Pagination />

                <table class="archive">
                    <thead>
                    <tr>
                        <th>Template Name</th>
                        <th>Department</th>
                        <th>Last modified</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><a href="#" title="" class="default-action">Graduate scheme</a></td>
                        <td>Motor claims</td>
                        <td>10:29 today</td>
                        <td>
                            <a href="#" title="">View</a>
                            <a href="#" title="">Edit</a>
                            <a href="#" title="">Copy</a>
                            <a href="#" title="">Archive</a>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <table class="pending">
                    <thead>
                    <tr>
                        <th>Template Name</th>
                        <th>Department</th>
                        <th>Last modified</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><a href="#" title="" class="default-action">Graduate scheme</a></td>
                        <td>Motor claims</td>
                        <td>10:29 today</td>
                        <td>
                            <a href="#" title="">View</a>
                            <a href="#" title="">Edit</a>
                            <a href="#" title="">Copy</a>
                            <a href="#" title="">Archive</a>
                        </td>
                    </tr>
                    </tbody>
                </table>


                <table class="drafts">
                    <thead>
                    <tr>
                        <th>Template Name</th>
                        <th>Department</th>
                        <th>Last modified</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><a href="#" title="" class="default-action">Graduate scheme</a></td>
                        <td>Motor claims</td>
                        <td>10:29 today</td>
                        <td>
                            <a href="#" title="">View</a>
                            <a href="#" title="">Edit</a>
                            <a href="#" title="">Copy</a>
                            <a href="#" title="">Archive</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <a href="#" title="" class="btn btn-primary">Edit</a> <a href="#" title="" class="btn btn-secondary">Copy</a> <a href="#" title="" class="btn btn-tertiary">Archive</a> <a href="#" title="" class="btn btn-frameless">Back to previous screen</a>

            <div class="columns">
                <div class="content">

                    <!-- FORM ELEMENTS -->
                    <div class="form-input">
                        <label for="name"><span class="tooltip" data-tooltip="Please, refer to Wikipedia!">Name:</span></label>
                        <input type="text" name="name" id="name" value="Sausage">
                    </div>

                    <div class="form-input">
                        <label for="date"><span class="tooltip" data-tooltip="Please, refer to Wikipedia!">Date:</span></label>
                        <div class="form-date">
                            <input type="date" name="date" id="date" date-format="dd/mm/yyyy" placeholder="dd/mm/yyyy">
                        </div>
                    </div>

                    <div class="form-input">
                        <label for="password"><span class="tooltip" data-tooltip="Please, refer to Wikipedia!">Password:</span></label>
                        <div class="form-password">
                            <input type="password" name="password" id="password" >
                            <span class="toggle-password"></span>
                        </div>
                    </div>

                    <div class="form-input">
                        <label for="comment"><span class="tooltip" data-tooltip="Pellentesque augue libero, vulputate nec elementum a, gravida sit amet neque. Donec tempus volutpat massa nec mollis. Mauris in eros enim. Cras vel porttitor risus.">Comment:</span></label>
                        <textarea-autosize name="comment" id="comment" rows="4" cols="50" value="Bingo bango bongo"></textarea-autosize>
                    </div>

                    <div class="form-input">
                        <label for="image">Select image</label>
                        <input type="hidden" name="image" id="image">
                        <span class="image image-border">
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" data-default-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" alt="Selected image" />
                        </span>
                        <a href="#" title="" class="image-select-sidebar-open">Select image</a>
                    </div>

                    <div class="form-input">
                        <label for="cars"><span class="tooltip" data-tooltip="Please, refer to Wikipedia!">My car:</span></label>
                        <div class="form-select">
                            <select name="cars" id="cars">
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-input">
                        <label ><span class="tooltip" data-tooltip="Please, refer to Wikipedia!">Checkbox label:</span></label>
                        <label for="thisisgreat" class="form-checkbox">
                            <input type="checkbox" name="thisisgreat" id="thisisgreat" /><span></span> Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great!Tick this if you are great! Tick this if you are great! Tick this if you are great!
                        </label>
                    </div>

                    <div class="form-input">
                        <label ><span class="tooltip" data-tooltip="Please, refer to Wikipedia!">Radio button label:</span></label>
                        <label for="soisthis" class="form-radio">
                            <input type="radio" name="soisthis" id="soisthis" /><span></span> Tick this if you are great!
                        </label>
                        <label for="soisthis2" class="form-radio">
                            <input type="radio" name="soisthis" id="soisthis2" /><span></span> Tick this if you are great! Tick this if you are great!
                        </label>
                    </div>

                    <div class="form-input form-file">
                        <input type="file" name="file" id="file"  />
                        <label for="file"><span class="label"><span class="tooltip" data-tooltip="Please, refer to Wikipedia!">Choose a file</span></span> <span class="btn btn-tertiary">Click to select</span> <span class="form-file-selected-name">No file selected</span></label>
                    </div>

                    <!-- OPTIONS USER SELECT -->
                    <div class="form-input options unlocked"> <!-- locked or nothing -->
                        <label for="myoptions" class="lock"><span class="tooltip" data-tooltip="Please, refer to Wikipedia!">Date:</span></label>
                        <div class="form-select">
                            <select name="cars" id="cars">
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                        <input type="text" name="myoptions" id="myoptions" value="Sausage">
                    </div>

                    <div class="form-input options locked"> <!-- locked or nothing -->
                        <label for="myoptionsta" class="lock"><span class="tooltip" data-tooltip="Please, refer to Wikipedia!">Date:</span></label>
                        <div class="form-select">
                            <select name="cars" id="cars">
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                        <textarea-autosize name="myoptionsta" id="myoptionsta" rows="4" cols="50" value="Bingo bango bongo" readonly="readonly"></textarea-autosize>
                    </div>

                    <div class="form-input options unlocked readonly ">
                        <label for="myoptions2" class="lock"><span class="tooltip" data-tooltip="Please, refer to Wikipedia!">Date:</span></label>

                    </div>
                    <div class="form-input options locked readonly">
                        <label for="myoptions3" class="lock"><span class="tooltip" data-tooltip="Please, refer to Wikipedia!">Date:</span></label>

                    </div>


                    <!-- OPTIONS TEXT ADMIN SELECT (TEMPLATE) -->
                    <div class="form-input options options-select unlocked"> <!-- locked or nothing -->
                        <span class="padlock-wrapper"><label for="myoptions-edit"><span class="tooltip" data-tooltip="Please, refer to Wikipedia!">Headline: (2 selected)</span></label><a href="#" title="" class="options-toggle">Switch to simple text entry</a><a href="#" title="" class="padlock unlocked">Toggle field lock</a></span>
                        <div class="options-accordion open">
                            <a class="options-accordion-heading"><span>Content options</span><span class="options-accordion-toggle-text options-accordion-toggle-text-show">Show</span><span class="options-accordion-toggle-text options-accordion-toggle-text-hide">Hide</span></a>
                            <ul class="options-accordion-list">
                                <li class="options-item">
                                    <label for="option1" class="form-checkbox">
                                        <input type="checkbox" name="option1" id="option1" /><span></span> <span><strong>Customer facing</strong><br/>The difference is our people.</span>
                                    </label>
                                </li>
                                <li class="options-item">
                                    <label for="option2" class="form-checkbox">
                                        <input type="checkbox" name="option2" id="option2" /><span></span> <span><strong>Customer facing</strong><br/>The difference is our people.</span>
                                    </label>
                                </li>
                                <li class="options-item">
                                    <label for="option3" class="form-checkbox">
                                        <input type="checkbox" name="option3" id="option3" /><span></span> <span><strong>Customer facing</strong><br/>Tell thi summat for nowt where there’s muck there’s brass shu’ thi gob. Dahn t’coil oil. That’s champion ey up will ‘e ‘eckerslike shurrup by ‘eck. Eeh. Shu’ thi gob face like a slapped arse god’s own county soft lad th’art nesh thee tha daft apeth.</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <input type="text" name="myoptions-edit" id="myoptions-edit" value="Sausage">
                    </div>

                    <!-- OPTIONS IMAGE ADMIN SELECT (TEMPLATE) -->
                    <div class="form-input options options-select unlocked"> <!-- locked or nothing -->
                        <span class="padlock-wrapper"><label for="myoptions-edit"><span class="tooltip" data-tooltip="Please, refer to Wikipedia!">Images: (2 selected)</span></label><a href="#" title="" class="padlock unlocked">Toggle field lock</a></span>
                        <div class="options-accordion open">
                            <a class="options-accordion-heading"><span>Content options</span><span class="options-accordion-toggle-text options-accordion-toggle-text-show">Show</span><span class="options-accordion-toggle-text options-accordion-toggle-text-hide">Hide</span></a>
                            <div class="options-library-select">
                                <label for="cars5">Select library</label>
                                <div class="form-select">
                                    <select name="cars5" id="cars5">
                                        <option value="volvo">Volvo</option>
                                        <option value="saab">Saab</option>
                                        <option value="mercedes">Mercedes</option>
                                        <option value="audi">Audi</option>
                                    </select>
                                </div>
                            </div>

                            <div class="options-accordion-image">
                                <div class="options-accordion-image-meta">
                                    <a href="#" title="#" class="select-all-visible">Select all visible</a>
                                    <span class="images-show">show <a href="#" title="#" class="images-number-select active">10</a> <a href="#" title="#" class="images-number-select">15</a> <a href="#" title="#" class="images-number-select">50</a> <a href="#" title="#" class="images-number-select">all</a></span>
                                </div>

                                <ul class="options-accordion-image-list">
                                    <li class="options-image-list-item">
                                        <label for="selectimage1a" class="form-checkbox image image-border"><input type="checkbox" name="selectimage1a" id="selectimage1a" /><span></span><img src="http://www.fillmurray.com/g/500/500" alt=""/></label>
                                        <p class="image-title">Lorem ipsum dolor sit amet</p>
                                    </li>
                                    <li class="options-image-list-item">
                                        <label for="selectimage2a" class="form-checkbox image image-border "><input type="checkbox" name="selectimage2a" id="selectimage2a" /><span></span><img src="http://www.fillmurray.com/g/300/500" alt=""/></label>
                                        <p class="image-title">Lorem ipsum</p>
                                    </li>
                                    <li class="options-image-list-item">
                                        <label for="selectimage3a" class="form-checkbox image image-border "><input type="checkbox" name="selectimage3a" id="selectimage3a" /><span></span><img src="http://www.fillmurray.com/g/500/300" alt=""/></label>
                                        <p class="image-title">Lorem ipsum</p>
                                    </li>
                                    <li class="options-image-list-item">
                                        <label for="selectimage4a" class="form-checkbox image image-border "><input type="checkbox" name="selectimage4a" id="selectimage4a" /><span></span><img src="http://www.fillmurray.com/g/600/500" alt=""/></label>
                                        <p class="image-title">Lorem ipsum</p>
                                    </li>
                                    <li class="options-image-list-item">
                                        <label for="selectimage5a" class="form-checkbox image image-border "><input type="checkbox" name="selectimage5a" id="selectimage5a" /><span></span><img src="http://www.fillmurray.com/g/300/700" alt=""/></label>
                                        <p class="image-title">Lorem ipsum</p>
                                    </li>
                                    <li class="options-image-list-item">
                                        <label for="selectimage6a" class="form-checkbox image image-border "><input type="checkbox" name="selectimage6a" id="selectimage6a" /><span></span><img src="http://www.fillmurray.com/g/550/500" alt=""/></label>
                                        <p class="image-title">Lorem ipsum</p>
                                    </li>
                                </ul>

                                <span>Insert pagination here</span>
                            </div>
                        </div>
                    </div>

                    <!-- DISABLED -->
                    <div class="form-input disabled readonly">
                        <label for="name1">Name: </label>
                        <input type="text" name="name1" id="name1" value="Sausage" disabled readonly>
                    </div>

                    <div class="form-input disabled readonly">
                        <label for="comment1">Comment:</label>
                        <textarea-autosize name="comment1" id="comment" rows="4" cols="50" value="Bingo bango bongo" disabled readonly></textarea-autosize>
                    </div>

                    <div class="form-input disabled readonly">
                        <label for="cars1">My car:</label>
                        <div class="form-select">
                            <select name="cars1" id="cars1" disabled readonly>
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-input disabled readonly">
                        <label >Checkbox label:</label>
                        <label for="thisisgreat1" class="form-checkbox">
                            <input type="checkbox" name="thisisgreat1" id="thisisgreat1" disabled readonly checked /><span></span> Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great!Tick this if you are great! Tick this if you are great! Tick this if you are great!
                        </label>
                    </div>

                    <div class="form-input disabled readonly">
                        <label >Radio button label:</label>
                        <label for="soisthis1" class="form-radio">
                            <input type="radio" name="soisthis1" id="soisthis1" disabled readonly checked /><span></span> Tick this if you are great!
                        </label>
                        <label for="soisthis3" class="form-radio">
                            <input type="radio" name="soisthis1" id="soisthis3" disabled readonly /><span></span> Tick this if you are great! Tick this if you are great!
                        </label>
                    </div>

                    <div class="form-input form-file disabled readonly">
                        <input type="file" name="file1" id="file1" disabled readonly />
                        <label for="file1"><span class="label">Choose a file</span> <span class="btn btn-tertiary">Click to select</span> <span class="form-file-selected-name">No file selected</span></label>
                    </div>

                    <!-- ERRORS-->

                    <div class="form-input error">
                        <label for="name3">Name:</label>
                        <input type="text" name="name3" id="name3" value="Sausage">
                        <span class="error-message">Error message here. Nullam consequat ex eu diam iaculis, sit amet pellentesque urna euismod. Vestibulum at eui</span>
                    </div>

                    <div class="form-input error">
                        <label for="comment3">Comment:</label>
                        <textarea-autosize name="comment3" id="comment3" rows="4" cols="50" value="Bingo bango bongo"></textarea-autosize>
                        <span class="error-message">Error message here</span>
                    </div>

                    <div class="form-input error">
                        <label for="cars3">My car:</label>
                        <div class="form-select">
                            <select name="cars3" id="cars3">
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                        <span class="error-message">Error message here</span>
                    </div>

                    <div class="form-input error">
                        <label >Checkbox label:</label>
                        <label for="thisisgreat3" class="form-checkbox">
                            <input type="checkbox" name="thisisgreat3" id="thisisgreat3" /><span></span> Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great!Tick this if you are great! Tick this if you are great! Tick this if you are great!
                        </label>
                        <span class="error-message">Error message here</span>
                    </div>

                    <div class="form-input error">
                        <label >Radio button label:</label>
                        <label for="soisthis3" class="form-radio">
                            <input type="radio" name="soisthis3" id="soisthis3" /><span></span> Tick this if you are great!
                        </label>
                        <label for="soisthis4" class="form-radio">
                            <input type="radio" name="soisthis3" id="soisthis4" /><span></span> Tick this if you are great! Tick this if you are great!
                        </label>
                        <span class="error-message">Error message here</span>
                    </div>

                    <div class="form-input form-file error">
                        <input type="file" name="file3" id="file3"  />
                        <label for="file3"><span class="label">Choose a file</span> <span class="btn btn-tertiary">Click to select</span> <span class="form-file-selected-name">No file selected</span></label>
                        <span class="error-message">Error message here</span>
                    </div>

                </div>
            </div>

            <ul class="image-list">
                <li class="image-list-item">
                    <a href="#" class="image image-border"><img src="http://www.fillmurray.com/g/500/500" alt=""/></a>
                    <p class="image-title">Lorem ipsum dolor sit amet</p>
                    <span class="image-meta">
                        <a href="#" title="">View</a>
                        <span class="form-input">
                            <label for="selectimage" class="form-checkbox">
                                <input type="checkbox" name="selectimage" id="selectimage" /><span></span>
                            </label>
                        </span>
                    </span>
                </li>
                <li class="image-list-item">
                    <a href="#" class="image image-border"><img src="http://www.fillmurray.com/g/300/500" alt=""/></a>
                    <p class="image-title">Lorem ipsum</p>
                    <span class="image-meta">
                        <a href="#" title="">View</a>
                        <span class="form-input">
                            <label for="selectimage1" class="form-checkbox">
                                <input type="checkbox" name="selectimage1" id="selectimage1" /><span></span>
                            </label>
                        </span>
                    </span>
                </li>
                <li class="image-list-item">
                    <a href="#" class="image image-border"><img src="http://www.fillmurray.com/g/500/300" alt=""/></a>
                    <p class="image-title">Lorem ipsum dolor sit</p>
                    <span class="image-meta">
                        <a href="#" title="">View</a>
                        <span class="form-input">
                            <label for="selectimage2" class="form-checkbox">
                                <input type="checkbox" name="selectimage2" id="selectimage2" /><span></span>
                            </label>
                        </span>
                    </span>
                </li>
                <li class="image-list-item">
                    <a href="#" class="image image-border"><img src="http://www.fillmurray.com/g/400/400" alt=""/></a>
                    <p class="image-title">Lorem</p>
                    <span class="image-meta">
                        <a href="#" title="">View</a>
                        <span class="form-input">
                            <label for="selectimage3" class="form-checkbox">
                                <input type="checkbox" name="selectimage3" id="selectimage3" /><span></span>
                            </label>
                        </span>
                    </span>
                </li>
                <li class="image-list-item">
                    <a href="#" class="image image-border"><img src="http://www.fillmurray.com/g/400/500" alt=""/></a>
                    <p class="image-title">Lorem ipsum dolor sit amet</p>
                    <span class="image-meta">
                        <a href="#" title="">View</a>
                        <span class="form-input">
                            <label for="selectimage4" class="form-checkbox">
                                <input type="checkbox" name="selectimage4" id="selectimage4" /><span></span>
                            </label>
                        </span>
                    </span>
                </li>
                <li class="image-list-item">
                    <a href="#" class="image image-border"><img src="http://www.fillmurray.com/g/500/400" alt=""/></a>
                    <p class="image-title">Lorem dolor sit amet</p>
                    <span class="image-meta">
                        <a href="#" title="">View</a>
                        <span class="form-input">
                            <label for="selectimage5" class="form-checkbox">
                                <input type="checkbox" name="selectimage5" id="selectimage5" /><span></span>
                            </label>
                        </span>
                    </span>
                </li>
                <li class="image-list-item">
                    <a href="#" class="image image-border"><img src="http://www.fillmurray.com/g/600/550" alt=""/></a>
                    <p class="image-title">Lorem ipsum dolor sit amet</p>
                    <span class="image-meta">
                        <a href="#" title="">View</a>
                        <span class="form-input">
                            <label for="selectimage6" class="form-checkbox">
                                <input type="checkbox" name="selectimage6" id="selectimage6" /><span></span>
                            </label>
                        </span>
                    </span>
                </li>
                <li class="image-list-item">
                    <a href="#" class="image image-border"><img src="http://www.fillmurray.com/g/750/200" alt=""/></a>
                    <p class="image-title">Lorem ipsum </p>
                    <span class="image-meta">
                        <a href="#" title="">View</a>
                        <span class="form-input">
                            <label for="selectimage" class="form-checkbox">
                                <input type="checkbox" name="selectimage" id="selectimage" /><span></span>
                            </label>
                        </span>
                    </span>
                </li>
            </ul>
            <Pagination />

            <div class="archive">
                <ul class="image-list">
                    <li class="image-list-item">
                        <a href="#" class="image image-border"><img src="http://www.fillmurray.com/g/500/500" alt=""/></a>
                        <p class="image-title">Lorem ipsum dolor sit amet</p>
                        <span class="image-meta">
                            <a href="#" title="">View</a>
                            <span class="form-input">
                                <label for="selectimage9" class="form-checkbox">
                                    <input type="checkbox" name="selectimage9" id="selectimage9" /><span></span>
                                </label>
                            </span>
                        </span>
                    </li>
                </ul>
            </div>

            <div class="title-editable">
                <div class="static">
                    <h1 class="title">Editable page title <a href="" title="" class="title-edit-trigger">Edit title</a></h1>
                </div>
                <div class="edit">
                    <div class="form-input">
                        <input type="text" name="name" id="name" value="Editable page title">
                    </div>
                    <div class="edit-options">
                        <a href="#" title="" class="title-edit-save">Save</a> <a href="#" title="" class="title-edit-cancel">Cancel</a>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="content">
                    <span class="image-border"><img src="http://www.fillmurray.com/g/500/820" alt=""/></span>
                </div>
                <div class="sidebar-right sidebar-right-full">
                    <a href="#" title="" class="btn btn-tertiary">Archive image</a>
                </div>
            </div>

            <div class="columns archive mt-8">
                <div class="content">
                    <span class="image-border"><img src="http://www.fillmurray.com/g/500/820" alt=""/></span>
                </div>
                <div class="sidebar-right sidebar-right-full">
                    <a href="#" title="" class="btn btn-tertiary">Archive image</a>
                </div>
            </div>

        </main>

        <aside id="image-select" class="image-select-sidebar">
            <div class="image-select-sidebar-content">
                <div class="image-select-sidebar-inner">
                    <h1 class="image-select-sidebar-title">Select image</h1>

                    <div class="form-input">
                        <label for="cars"><span class="tooltip" data-tooltip="Please, refer to Wikipedia!">Select image library</span></label>
                        <div class="form-select">
                            <select name="cars" id="cars">
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                    </div>

                    <div class="image-select-sidebar-image-list">
                        <ul class="image-list">
                            <li class="image-list-item">
                                <a href="#" class="image image-border image-select"><img src="http://www.fillmurray.com/g/500/500" alt=""/></a>
                                <p class="image-title">Lorem ipsum dolor sit amet</p>
                                <span class="image-meta">
                                    <a href="#" title="" class="image-select">Select</a>
                                </span>
                            </li>
                            <li class="image-list-item">
                                <a href="#" class="image image-border image-select"><img src="http://www.fillmurray.com/g/300/500" alt=""/></a>
                                <p class="image-title">Lorem ipsum</p>
                                <span class="image-meta">
                                    <a href="#" title="" class="image-select">Select</a>
                                </span>
                            </li>
                            <li class="image-list-item">
                                <a href="#" class="image image-border image-select"><img src="http://www.fillmurray.com/g/500/300" alt=""/></a>
                                <p class="image-title">Lorem ipsum dolor sit</p>
                                <span class="image-meta">
                                    <a href="#" title="" class="image-select">Select</a>
                                </span>
                            </li>
                            <li class="image-list-item">
                                <a href="#" class="image image-border image-select"><img src="http://www.fillmurray.com/g/400/400" alt=""/></a>
                                <p class="image-title">Lorem</p>
                                <span class="image-meta">
                                    <a href="#" title="" class="image-select">Select</a>
                                </span>
                            </li>
                            <li class="image-list-item">
                                <a href="#" class="image image-border image-select"><img src="http://www.fillmurray.com/g/400/500" alt=""/></a>
                                <p class="image-title">Lorem ipsum dolor sit amet</p>
                                <span class="image-meta">
                                    <a href="#" title="" class="image-select">Select</a>
                                </span>
                            </li>
                            <li class="image-list-item">
                                <a href="#" class="image image-border image-select"><img src="http://www.fillmurray.com/g/500/400" alt=""/></a>
                                <p class="image-title">Lorem dolor sit amet</p>
                                <span class="image-meta">
                                    <a href="#" title="" class="image-select">Select</a>
                                </span>
                            </li>
                            <li class="image-list-item">
                                <a href="#" class="image image-border image-select"><img src="http://www.fillmurray.com/g/600/550" alt=""/></a>
                                <p class="image-title">Lorem ipsum dolor sit amet</p>
                                <span class="image-meta">
                                    <a href="#" title="" class="image-select">Select</a>
                                </span>
                            </li>
                            <li class="image-list-item">
                                <a href="#" class="image image-border image-select"><img src="http://www.fillmurray.com/g/750/200" alt=""/></a>
                                <p class="image-title">Lorem ipsum </p>
                                <span class="image-meta">
                                    <a href="#" title="" class="image-select">Select</a>
                                </span>
                            </li>
                        </ul>
                        <Pagination />
                    </div>

                    <div class="image-select-cancel">
                        <a href="#" title="" class="btn btn-tertiary image-select-sidebar-close">Cancel</a>
                    </div>
                </div>
            </div>
        </aside>

        <Footer />
    </div>
</template>

<script>
    import Header from '@/components/header';
    import Footer from '@/components/footer';
    import Breadcrumbs from '@/components/breadcrumbs';
    import Help from '@/components/help';
    import Pagination from '@/components/pagination';

    import 'date-input-polyfill';

    export default {
        name: 'app',
        components: {Header, Footer, Breadcrumbs, Help, Pagination },
        methods:{
            initFileInput() {
                // File input JS - should be moved to component
                const fileInputs = document.querySelectorAll( '.form-file' );

                for (let wrapper of fileInputs)
                {
                    let input = wrapper.querySelector("input");

                    let label = input.nextElementSibling,
                        fileNameElement = label.querySelector(".form-file-selected-name"),
                        labelVal = fileNameElement.innerHTML;

                    // TODO: Refactor using @click binding?
                    input.addEventListener( 'change', function( e )
                    {
                        let fileName = ( this.files && this.files.length > 0 ) ? e.target.value.split( '\\' ).pop() : null;

                        fileNameElement.innerHTML = fileName ? fileName : labelVal;
                    });
                }
            },
            initPasswordInput() {
                const togglePasswordTrigger = document.querySelector('.toggle-password');
                const password = document.querySelector('#password');

                // TODO: Refactor using @click binding?
                togglePasswordTrigger.addEventListener('click', function (e) {
                    e.preventDefault();

                    // toggle the type attribute
                    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
                    password.setAttribute('type', type);

                    // toggle the eye slash icon
                    this.classList.toggle('is-plain-text');
                });
            },

            initEditTitle() {
                const toggleEditTitleWrapper = document.querySelector('.title-editable');
                const toggleEditTitleTrigger = toggleEditTitleWrapper.querySelector('.title-edit-trigger');
                const editTitleSave = toggleEditTitleWrapper.querySelector('.title-edit-save');
                const editTitleCancel = toggleEditTitleWrapper.querySelector('.title-edit-cancel');

                // eslint-disable-next-line no-console
                console.log([toggleEditTitleWrapper, toggleEditTitleTrigger]);


                // TODO: Refactor using @click binding?
                toggleEditTitleTrigger.addEventListener('click', e => {
                    e.preventDefault();
                    this.editTitleShowInput(toggleEditTitleWrapper);
                });

                editTitleSave.addEventListener('click', e => {
                    e.preventDefault();
                    this.editTitleHideInput(toggleEditTitleWrapper);
                });

                editTitleCancel.addEventListener('click', e => {
                    e.preventDefault();
                    this.editTitleHideInput(toggleEditTitleWrapper);
                });
            },
            editTitleShowInput (toggleEditTitleWrapper) {
                toggleEditTitleWrapper.classList.add('editing');
            },
            editTitleHideInput (toggleEditTitleWrapper) {
                toggleEditTitleWrapper.classList.remove('editing');
            },

            initImageSelectSidebar() {
                const sidebarOpenTriggers = document.querySelectorAll( '.image-select-sidebar-open' );
                const sidebarCloseTriggers = document.querySelectorAll( '.image-select-sidebar-close' );
                const sidebarImageSelectTriggers = document.querySelectorAll( '.image-select' );
                const sidebar = document.querySelector('.image-select-sidebar');

                for (let trigger of sidebarOpenTriggers)
                {
                    // TODO: Refactor using @click binding?
                    trigger.addEventListener( 'click',  e => {
                        e.preventDefault();
                        this.openImageSelectSidebar(sidebar);
                    });
                }
                for (let trigger of sidebarCloseTriggers)
                {
                    // TODO: Refactor using @click binding?
                    trigger.addEventListener( 'click',  e => {
                        e.preventDefault();
                        this.closeImageSelectSidebar(sidebar);
                    });
                }
                for (let trigger of sidebarImageSelectTriggers)
                {
                    // TODO: Refactor using @click binding?
                    trigger.addEventListener( 'click',  e => {
                        e.preventDefault();
                        this.imageSelected(sidebar, trigger);
                    });
                }
            },
            openImageSelectSidebar(sidebar) {
                sidebar.classList.add('active');
            },
            closeImageSelectSidebar(sidebar) {
                sidebar.classList.remove('active');
            },
            imageSelected(sidebar/*, selectedImage*/) {
                this.closeImageSelectSidebar(sidebar);
            }
        },
        mounted() {
            this.initFileInput();
            this.initPasswordInput();
            this.initEditTitle();
            this.initImageSelectSidebar();
        },
    }




</script>

<style>

</style>
