<template>
    <div class="form-input" :class="[classString, {'error': error}, {'locked': element.locked === true, 'unlocked' : element.locked === false}]">
        <span class="padlock-wrapper"><label :for="element.name" class="lock"><Label :label="element.label" :tooltip="element.tooltip" /></label></span>
        <div class="form-password">
            <input autocomplete="off" type="password" :id="element.name" v-model="inputValue" :disabled="element.disabled" :readonly="element.readonly" @focus="onInputFocusStandard" @blur="onInputBlurStandard">
            <span :class="'toggle-password-' + element.name" @click="togglePasswordInput($event);" ></span>
        </div>
        <span class="error-message" v-if="error">{{error}}</span>
    </div>
</template>

<script>
    import Label from "@/components/form/label";
    import {formElementMixin} from "@/components/mixins/formelement";

    export default {
        name: "Formelement_password",
        components: {Label},
        mixins: [formElementMixin],
        props: {
            element: {
                type: Object
            },
            value: {
                type: String
            },
            classString: {
                type: String
            }
        },
        methods: {
            togglePasswordInput(e) {
                e.preventDefault();

                const password = document.querySelector('#' + this.element.name);
                const toggler = document.querySelector('.toggle-password-' + this.element.name);

                // toggle the type attribute
                const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
                password.setAttribute('type', type);

                // toggle the eye slash icon
                toggler.classList.toggle('is-plain-text');
            },
            clear() {
                document.getElementById(this.element.name).value = "";
            },
        },
        computed: {
            inputValue: {
                get() { return this.value },
                set(inputValue) {this.$emit('input', inputValue)}
            }
        }
    }
</script>