<template>
    <div class="form-input options options-select mediapicker-edit" :class="[classString, {'error': error}, {'locked': element.locked === true, 'unlocked' : element.locked === false}]"> <!-- locked or nothing -->
        <span class="padlock-wrapper"><label :for="element.name"><Label :label="labelText" :tooltip="element.tooltip" /></label><Lock :locked="element.locked" @toggleLocked="toggleLockedState($event)" /></span>

        <ul class="mediapicker-selected-list" v-if="selectedOptions.length > 0">
            <li class="mediapicker-selected-list-item" v-for="row in mediaItemShortList" :key="row.id">
                <div class="image image-border">
                    <img v-auth-src="mediaBasePath + row.thumbnailFilePath" :alt="row.name | cleanString" />
                </div>
                <a href="#" :title="text.remove_media" @click="removeMediaFromSelected($event, row.id)" class="close">{{text.remove_media}}</a>
            </li>
        </ul>

        <div :id="element.name" class="options-accordion" :class="[{'open' : showOptions}, {'closed' : showOptions === false}]">
            <a class="options-accordion-heading" @click="toggleAccordion($event);"><span>{{text.content_options}}</span><span class="options-accordion-toggle-text options-accordion-toggle-text-show">{{text.show}}</span><span class="options-accordion-toggle-text options-accordion-toggle-text-hide">{{text.hide}}</span></a>
            <div class="options-accordion-content">
                <div class="options-library-select">
                    <label :for="element.name + '-mediaGroupSelect'">{{text.select_media_group}}</label>
                    <div class="form-select">
                        <select autocomplete="off" :id="element.name + '-mediaGroupSelect'" v-model="selectedMediaGroupID">
                            <option value="0" disabled>- {{text.select_option}} -</option>
                            <option v-for="(option, index) in mediaGroupSelectOptions" :key="index" :value="option.value">{{option.text}}</option>
                        </select>
                    </div>
                </div>

                <div class="options-accordion-image" v-if="selectedMediaGroupID > 0">
                    <div class="options-accordion-image-meta" v-if="mediaItemList.length > 0">
                        <a href="#" :title="text.select_all_visible" class="select-all-visible" @click="selectAllVisibleImages($event);">{{text.select_all_visible}}</a>
                        <span class="images-show">show <a href="#" :title="text.show + ' 10'" class="images-number-select" :class="[{'active' : mediaItemListNumberPerPage === 10}]" @click="onNumberPerPageChange($event, 10);">10</a> <a href="#" :title="text.show + ' 15'" class="images-number-select" :class="[{'active' : mediaItemListNumberPerPage === 15}]" @click="onNumberPerPageChange($event, 15);">15</a> <a href="#" :title="text.show + ' 50'" class="images-number-select" :class="[{'active' : mediaItemListNumberPerPage === 50}]" @click="onNumberPerPageChange($event, 50);">50</a> <a href="#" :title="text.show + ' ' + text.all" class="images-number-select" :class="[{'active' : mediaItemListNumberPerPage === 10000}]" @click="onNumberPerPageChange($event, 10000);">{{text.all}}</a></span>
                    </div>

                    <ul class="options-accordion-image-list" v-if="mediaItemList.length > 0">
                        <li class="options-image-list-item" v-for="row in mediaItemList" :key="row.id">
                            <label :for="element.name + '-selectimage' + row.id" class="form-checkbox image image-border"><input autocomplete="off" type="checkbox" :id="element.name + '-selectimage' + row.id" :value="row.id" v-model="selectedOptions" @focus="onInputFocusSelectedOptions" @blur="onInputBlurSelectedOptions" /><span></span><img v-auth-src="mediaBasePath + row.thumbnailFilePath" :alt="row.name | cleanString" /></label>
                            <p class="image-title">{{row.name}}</p>
                        </li>
                    </ul>
                    <p class="pt-4 pb-4" v-else>{{text.no_image_results_edit}}</p>

                    <Pagination v-if="mediaItemListPagination.totalPages > 0" :pagination="mediaItemListPagination" @go-previous="refreshMediaItemList" @go-next="refreshMediaItemList" />
                </div>
            </div>
        </div>

        <span class="error-message" v-if="error">{{error}}</span>
    </div>
</template>

<script>
    import {log} from '@/shared/utils';
    import lang from '@/shared/lang';

    import Label from "@/components/form/label";
    import Lock from "@/components/form/lock";
    import Pagination from "@/components/pagination";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {formElementMixin} from "@/components/mixins/formelement";
    import {formElementPickerMixin} from "@/components/mixins/formelement_picker";
    import {formElementMediaPickerMixin} from "@/components/mixins/formelement_mediapicker";

    export default {
        name: "Formelement_mediapicker_edit",
        components: {Label, Lock, Pagination},
        mixins: [formatContentMixin, formElementMixin, formElementPickerMixin, formElementMediaPickerMixin],
        props: {
            element: {
                type: Object
            },
            value: {
                type: [String, Array, Number]
            },
            classString: {
                type: String
            }
        },
        computed: {
            text() {
                return lang;
            },
            inputValue: {
                get() { return parseInt(this.value) },
                set(inputValue) {this.$emit('input', inputValue)}
            },
        },
        methods: {
            clear() {
                document.getElementById(this.element.name).value = "";
            },
            onNumberPerPageChange(e, newValue){
                e.preventDefault();
                this.changeNumberPerPage(newValue);
            },
            selectAllVisibleImages(e) {
                e.preventDefault();
                log(["Selecting all visible", ]);

                this.mediaItemList.forEach((mediaItem) => {
                    this.selectedOptions.push(mediaItem.id)
                })

                this.selectedOptions = [...new Set(this.selectedOptions)];
            },
            removeMediaFromSelected(e, id){
                e.preventDefault();
                log(["Removing from selected list", id]);
                let imageIndex = this.selectedOptions.indexOf(id);
                if(imageIndex > -1){
                    this.selectedOptions.splice(imageIndex, 1);
                }
            }
        },
        async mounted() {
            await this.initMediaGroupSelect();
        },
        watch:{
            selectedMediaGroupID: {
                handler: function() {
                    this.refreshMediaItemList();
                },
                immediate: true
            },
            selectedOptions: {
                handler: async function() {
                    await this.loadMediaItemsByID(this.selectedOptions);
                },
            }
        },
    }
</script>