import Vue from 'vue';
import App from './App.vue';

import Vuex from 'vuex';
import 'es6-promise/auto';

import router from './router';

import TextareaAutosize from 'vue-textarea-autosize';
import './assets/styles/index.scss';
import store from './store';

import VueAuthImage from './shared/vendor/vue-auth-image';

Vue.use(Vuex);
Vue.use(TextareaAutosize);
Vue.config.productionTip = false;

Vue.use(VueAuthImage);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
