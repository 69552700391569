// define a mixin object
import lang from "@/shared/lang";
import {/*log, */createDateString, createDateTimeString} from "@/shared/utils";

export const formatContentMixin = {
    filters: {
        createdByName: function (name, isOwner) {
            return isOwner ? lang.asset_owner_identifier : name;
        },
        formatDate: function (date) {
            return createDateString(date);
        },
        formatDateTime: function (date) {
            return createDateTimeString(date);
        },
        cleanString: function (string){
            //log(["Cleaning string", string]);
            return string.replace(/[^a-zA-Z0-9 ]+/g, '');
        }
    }
}