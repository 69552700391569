<template>
    <header class="header root-element">
        <div class="header-meta">
            <router-link :to="{ name: 'Home' }" title="Home"><img src="@/assets/images/logo.png" alt="Calgary Connex" class="logo" /></router-link>

            <div class="current-user" v-if="user.name">
                <span class="name">{{user.name}} <span v-if="user.isAdmin">(Administrator)</span></span>
                <router-link :to="{ name: 'UserHome' }" v-if="user.isAdmin && section === 'Admin'" title="Switch to user">Switch to user</router-link>
                <router-link :to="{ name: 'AdminHome' }" v-if="user.isAdmin && section === 'User'" title="Switch to admin">Switch to admin</router-link> <span v-if="user.isAdmin">/</span> <router-link :to="{ name: 'Logout' }" title="Home">Logout</router-link>
            </div>
        </div>

        <nav class="navigation-main" v-if="section === 'Admin'">
            <ul>
                <li><router-link :to="{ name: 'AdminHome' }" title="Home" class="navigation-btn">Home</router-link></li>
                <li><router-link :to="{ name: 'AdminTemplates' }" title="Templates" class="navigation-btn">Templates</router-link><ul>
                    <li><router-link :to="{ name: 'AdminTemplateNew' }" title="Create new template" class="navigation-btn navigation-btn-secondary">Create new</router-link></li>
                    <li><router-link :to="{ name: 'AdminTemplateList' }" title="All templates" class="navigation-btn navigation-btn-secondary">All templates</router-link></li>
                    <li><router-link :to="{ name: 'AdminTemplateArchive' }" title="Archived templates" class="navigation-btn navigation-btn-secondary">Archive</router-link></li>
                </ul></li>
                <li><router-link :to="{ name: 'AdminAdverts' }" title="Adverts" class="navigation-btn">Adverts</router-link><ul>
                    <li><router-link :to="{ name: 'AdminAdvertsNew' }" title="Create new" class="navigation-btn navigation-btn-secondary">Create new</router-link></li>
                    <li><router-link :to="{ name: 'AdminAdvertsList' }" title="All adverts" class="navigation-btn navigation-btn-secondary">All adverts</router-link></li>
                    <li><router-link :to="{ name: 'AdminAdvertsArchive' }" title="Archive" class="navigation-btn navigation-btn-secondary">Archive</router-link></li>
                </ul></li>
                <li><router-link :to="{ name: 'AdminMediaGroup' }" title="Images" class="navigation-btn">Images</router-link><ul>
                    <li><router-link :to="{ name: 'AdminMediaGroupNew' }" title="Create new library" class="navigation-btn navigation-btn-secondary">Create new library</router-link></li>
                    <li><router-link :to="{ name: 'AdminMediaGroupList' }" title="All image libraries" class="navigation-btn navigation-btn-secondary">All image libraries</router-link></li>
                    <li><router-link :to="{ name: 'AdminMediaGroupArchive' }" title="Archive" class="navigation-btn navigation-btn-secondary">Archive</router-link></li>
                </ul></li>
                <li><router-link :to="{ name: 'AdminReporting' }" title="Reporting" class="navigation-btn">Reporting</router-link></li>
                <li><router-link :to="{ name: 'AdminMessages' }" title="Messages" class="navigation-btn">Messages</router-link><ul>
                    <li><router-link :to="{ name: 'AdminMessagesNew' }" title="Create new" class="navigation-btn navigation-btn-secondary">Create new</router-link></li>
                    <li><router-link :to="{ name: 'AdminMessagesList' }" title="All messages" class="navigation-btn navigation-btn-secondary">All messages</router-link></li>
                    <li><router-link :to="{ name: 'AdminMessagesArchive' }" title="Deleted messages" class="navigation-btn navigation-btn-secondary">Deleted messages</router-link></li>
                </ul></li>
                <li><router-link :to="{ name: 'AdminTemplateSettings' }" title="Content management" class="navigation-btn">Content management</router-link><ul>
                  <li><router-link :to="{ name: 'AdminTemplateSchemaSettingsCollectionProperties' }" title="Properties" class="navigation-btn navigation-btn-secondary">Properties</router-link></li>
                  <li><router-link :to="{ name: 'AdminTemplateSchemaSettingsCollection' }" title="Content options" class="navigation-btn navigation-btn-secondary">Content</router-link></li>
                </ul></li>
                <li><router-link :to="{ name: 'AdminSystem' }" title="System" class="navigation-btn">System</router-link><ul>
                    <li><router-link :to="{ name: 'AdminUserList' }" title="Users" class="navigation-btn navigation-btn-secondary">Users</router-link></li>
                    <li><router-link :to="{ name: 'AdminBusinessUnit' }" title="Departments" class="navigation-btn navigation-btn-secondary">Departments</router-link></li>
                    <li><router-link :to="{ name: 'AdminFAQs' }" title="FAQs" class="navigation-btn navigation-btn-secondary">FAQs</router-link></li>
                    <li><router-link :to="{ name: 'AdminInPageHelp' }" title="In page help" class="navigation-btn navigation-btn-secondary">In page help</router-link></li>
                </ul></li>
            </ul>
        </nav>

        <nav class="navigation-main" v-else-if="section === 'User'">
            <ul>
                <li><router-link :to="{ name: 'UserHome' }" title="Home" class="navigation-btn">Home</router-link></li>
                <li><router-link :to="{ name: 'UserAdverts' }" title="Adverts" class="navigation-btn">Adverts</router-link><ul>
                    <li><router-link :to="{ name: 'UserAdvertsNew' }" title="Create new" class="navigation-btn navigation-btn-secondary">Create new</router-link></li>
                    <li><router-link :to="{ name: 'UserAdvertsList' }" title="All adverts" class="navigation-btn navigation-btn-secondary">Your adverts</router-link></li>
                    <li><router-link :to="{ name: 'UserAdvertsArchive' }" title="Archive" class="navigation-btn navigation-btn-secondary">Archive</router-link></li>
                </ul></li>
                <li><router-link :to="{ name: 'UserMessages' }" title="Messages" class="navigation-btn">Messages</router-link><ul>
                    <li><router-link :to="{ name: 'UserMessagesList' }" title="All messages" class="navigation-btn navigation-btn-secondary">Your messages</router-link></li>
                </ul></li>
                <li><router-link :to="{ name: 'UserFAQs' }" title="FAQs" class="navigation-btn">FAQs</router-link></li>
            </ul>
        </nav>

        <nav class="navigation-main" v-else-if="section === 'Default'">
            <ul>
                <li><router-link :to="{ name: 'Login' }" title="Login" class="navigation-btn">Login</router-link></li>
            </ul>
        </nav>

    </header>
</template>

<script>
    import {log} from '@/shared/utils';
    import {mapState, mapActions, } from "vuex";

    export default {
        name: "Header",
        components: {},
        props: {},
        computed: {
            ...mapState([ 'user', 'section' ]), // imports from store
            user() {
                return this.$store.state.user;
            },
            section() {
                return this.$store.state.section;
            },
        },
        methods: {
            ...mapActions(["setSectionAction"]),
            updateSection(route) {
                log(["ROUTE", route]);

                if(route.some(record => record.meta.isAdminContent)) {
                    this.setSectionAction('Admin');
                }
                else if(route.some(record => record.meta.isUserContent)){
                    this.setSectionAction('User');
                }
                else
                {
                    this.setSectionAction('Default');
                }
            }
        },
        created() {
            this.updateSection(this.$route.matched);
        },
        watch:{
            $route (to){
                this.updateSection(to.matched)
            }
        }

    }
</script>

<style lang="scss" scoped>


</style>