<template>
    <div class="title-editable" v-if="pageTitle.isEditable" :class="{ editing : isediting }">
        <div class="static">
            <h1 class="title">{{pageTitle.title}} <a href="#" :title="text.edit_title" class="title-edit-trigger" @click="showInput($event)" @keyup.enter="showInput($event)">{{text.edit_title}}</a></h1>
        </div>
        <div class="edit">
            <div class="form-input">
                <input type="text" name="title" id="title" v-model="pageTitle.editableTitle">
            </div>
            <div class="edit-options">
                <a href="#" :title="text.save" class="title-edit-save" @click="savePageTitle($event)" @keyup.enter="savePageTitle($event)">{{text.save}}</a> <a href="#" :title="text.cancel" class="title-edit-cancel" @click="hideInput($event)" @keyup.enter="hideInput($event)">{{text.cancel}}</a>
            </div>
        </div>
    </div>
    <h1 class="title" v-else>{{pageTitle.title}}</h1>
</template>

<script>
    import lang from "@/shared/lang";
    import {log} from "@/shared/utils";

    import {mapActions, mapState} from "vuex";

    export default {
        name: "PageTitle",
        data() {
            return {
                isediting: false
            }
        },
        computed: {
            ...mapState([ 'pageTitle' ]), // imports from store
            text() {
                return lang;
            },
        },
        methods: {
            ...mapActions(["setPageTitleAction"]),

            showInput (e) {
                e.preventDefault();
                this.isediting = true;
            },
            hideInput (e) {
                e.preventDefault();
                this.isediting = false;
            },
            savePageTitle(e) {
                e.preventDefault();
                log(["Saving title", this.pageTitle.editableTitle, this.pageTitle.title, this.pageTitle.changeSaved]);
                this.pageTitle.changeSaved = true;
                this.hideInput(e);
            }
        },

    }
</script>