<template>
    <nav class="breadcrumbs" v-if="breadCrumbs.length > 0">
        <ul>
            <li v-for="(breadcrumb, index) in breadCrumbs" :key="breadcrumb.name">
                <router-link :to="{ name : breadcrumb.name }" :title="breadcrumb.title" class="navigation-btn" v-if="(index < (breadCrumbs.length - 1))">{{breadcrumb.title}}</router-link>
                <span class="navigation-btn" v-if="(index == (breadCrumbs.length - 1))">{{breadcrumb.title}}</span>
            </li>
        </ul>
    </nav>
</template>

<script>
    //import {log} from "@/shared/utils";

    import {mapActions, mapState} from "vuex";

    export default {
        name: "Breadcrumbs",
        computed: {
            ...mapState(['breadCrumbs']), // imports from store
        },
        methods: {
            ...mapActions(["setBreadCrumbsAction"]),
        },
        created() {
            this.setBreadCrumbsAction({
                route: this.$route.matched
            });
        },
        watch:{
            $route (to){
                this.setBreadCrumbsAction({
                    route: to.matched
                });
            }
        }
    }
</script>