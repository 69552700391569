<template>
    <span v-if="tooltip" class="tooltip" :data-tooltip="tooltip">{{label}}:</span>
    <span v-else>{{label}}:</span>
</template>

<script>
    import {log} from '@/shared/utils';

    export default {
        name: "Label",
        props: {
            label: {
                type: String,
            },
            tooltip: {
                type: String,
            },
        },
        watch: {
            label: function(newVal, oldVal) { // watch it
                log('label changed: ', newVal, ' | was: ', oldVal)
            }
        }
    }
</script>