<template>
    <div class="form-input" :class="[classString, {'error': error}, {'locked': element.locked === true, 'unlocked' : element.locked === false}]">
        <span v-if="element.isTemplateContentEdit" class="padlock-wrapper"><label :for="element.name"><Label :label="element.label" :tooltip="element.tooltip" /></label><Lock :locked="element.locked" @toggleLocked="toggleLockedState($event)" /></span>
        <label v-else :for="element.name" class="lock"><Label :label="element.label" :tooltip="element.tooltip" /></label>

        <div class="form-date">
            <input autocomplete="off" type="date" :id="element.name" date-format="dd/mm/yyyy" :placeholder="element.placeholder" v-model="inputValue" :disabled="element.disabled" :readonly="element.readonly" @focus="onInputFocusStandard" @blur="onInputBlurStandard">
        </div>
        <span class="error-message" v-if="error">{{error}}</span>
    </div>
</template>

<script>

    import {formElementMixin} from "@/components/mixins/formelement";

    import 'date-input-polyfill';

    import Label from "@/components/form/label";
    import Lock from "@/components/form/lock";

    export default {
        name: "Formelement_date",
        components: {Label, Lock},
        mixins: [formElementMixin],
        props: {
            element: {
                type: Object
            },
            value: {
                type: String
            },
            classString: {
                type: String
            }
        },
        computed: {
            inputValue: {
                get() { return this.value },
                set(inputValue) {this.$emit('input', inputValue)}
            }
        },
    }
</script>