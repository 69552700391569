const textStrings = {
    // meta
    'meta_page_title_suffix' : " - Calgary Connex",

    // 404
    'title_404'     :   'Page not found 404',
    'body_404'      :   "<p>The page you are looking for could not be found. Please use the navigation to browse available content.</p>",

    // Maintenance
    'title_maintenance'     :   'System unavailable',
    'body_maintenance'      :   "<p>The system cannot be reached at present. Most likely this is due to scheduled maintenance. Please try again later.</p>",

    // Confirm email
    'title_confirm_email'   :   'Welcome to Connex',
    'body_confirm_email'    :   "<p>Thank you for confirming your email address. You will now need to Login.</p>",

    // Password reset
    'title_begin_password_reset'    :   "Reset password",
    'body_begin_password_reset'     :   "<p>Enter your email address below to be sent a link to reset your password.</p>",
    'title_do_password_reset'       :   "Enter new password",
    'body_do_password_reset'        :   "<p>Enter your new password here.</p>",
    'body_do_password_reset_done'   :   "<p>Your password has been reset. You can now Login.</p>",

    // messages
    'message_login_fail'            :   'Your Email and Password do not match an account on our system.',
    'message_logout_fail'           :   'You could not be logged out at this time.',
    'message_logout'                :   'You have successfully logged out.',
    'message_not_admin'             :   "You don't have permission to view attempted content",
    'message_no_permission'         :   "You don't have permission to view this content, please log in again.",
    'message_bad_server_response'   :   'The requested action could not be completed due to a server issue. Please try again later.',
    'message_content_saved'         :   'New content saved ok',
    'message_media_item_upload_error':  'Media file could not be uploaded at this time. Please try again later.',

    'message_in_page_help_edit_saved'       :   'In page help updated',

    'message_business_unit_edit_saved'      :   'Department name updated',
    'message_business_unit_added'           :   'New department added',

    'message_image_edit_saved'              :   'Image updated',
    'message_image_edit_as_new'             :   'New image created',

    'message_media_group_edit_saved'        :   'Library name updated',
    'message_media_group_added'             :   'New library added',

    'message_message_edit_saved'    :   "Message saved",
    'message_message_added'         :   "Message added",
    'message_message_deleted'       :   "Message deleted",
    'message_message_archived'      :   "Message archived",

    'message_template_schema_settings_collection_edit_saved' : "Options updated",

    'message_template_copied'           :   "Template copied! You can edit your copy below.",
    'message_template_saved'            :   "Template saved!",
    'message_template_sections_saved'   :   "Template sections saved",
    'message_advert_copied'             :   "Advert copied! You can edit your copy below.",

    'message_user_added'            :   "User added",
    'message_user_edit_saved'       :   "User updated",

    'message_email_confirmation_error'  :   "There was a problem. Please try the link in your email again or contact an administrator",

    'message_begin_password_reset_success'  : "If the email address matches an account, then you will receive an email with a reset link. Please check your inbox and SPAM folders.",

    'message_confirm_message_delete'        :   "Are you sure you want to delete this message?",
    'message_confirm_content_update_delete' :   "Are you sure you want to delete this option?",

    'advert_publish_error'          :   "Your advert could not be published at this time",

    // headings
    'user_draft_advert_table'       :   "Your draft adverts",
    'admin_draft_advert_table'      :   "Draft adverts",
    'user_live_adverts_panel'       :   "Your live adverts",
    'user_pending_advert_table'     :   "Pending adverts",
    'admin_pending_advert_table'    :   "Pending adverts",
    'user_active_advert_table'      :   "Active adverts",
    'admin_active_advert_table'     :   "Active adverts",
    'user_home_subtitle'            :   "Get started",
    'preview_advert_formats'        :   "Preview formats",
    'preview_advert_formats_note'   :   "Continue to export screen to see all available formats.",
    'select_sections'               :   "Select template sections",

    // table titles
    'name'          :   'Name',
    'text'          :   'Text',
    'page_alias'    :   'Page Alias',
    'page_name'     :   'Page Name',
    'advert_name'   :   'Advert Name',
    'template_name' :   'Template Name',
    'media_group_name'   :   'Image Library Name',
    'business_unit' :   'Department',
    'created_by'    :   'Created by',
    'actions'       :   'Actions',
    'date_last_modified' :   'Last modified',
    'date_live'     :   'Live date',
    'date_expiry'   :   'Expiry date',
    'date_archived' :   'Archived date',
    'headline'      :   'Headline',
    'published'     :   'Published',
    'published_date':   'Published date',
    'editor_type'   :   'Editor type',
    'property_title':   'Section Title',
    'rename'        :   'Rename',
    'tooltip'       :   'Tooltip',
    'disable'       :   'Disable section',
    'no_results'    :   'No rows to display',
    'no_options_available'   :   'No options available',
    'no_options_selected'    :   'No options selected',
    'no_image_results_edit'  :   'No options selected',
    'no_image_results_view'  :   'No default options available',
    'no_preview_formats':   'No preview formats available',
    'content_options'   :   'Content options',
    'user_role'         :   'Role',
    'messages'          :   "Messages",
    'report_total_advert_published'     :   "Total adverts published",
    'report_user_caseload'              :   "User caseload",
    'report_user_caseload_limited'      :   "User caseload (top 3)",
    'report_business_unit_caseload'              :   "Department caseload",
    'report_business_unit_caseload_limited'      :   "Department caseload (top 3)",
    'preview'       :   'Preview',

    // actions
    'edit'          :   'Edit',
    'view'          :   'View',
    'add'           :   'Add',
    'delete'        :   'Delete',
    'copy'          :   'Copy',
    'archive'       :   'Archive',
    'restore'       :   'Restore',
    'all'           :   'all',
    'select'        :   'Select',
    'create'        :   'Create',
    'export'        :   'Export',
    'view_all_reports'  :   'View all reports',
    'disable_user'      :   'Disable',
    'enable_user'       :   'Enable',


    // buttons
    'login'                     :   'Login',
    'save'                      :   'Save',
    'save_as_new'             :     'Save and keep original',
    'save_as_update'          :   'Save and replace original',
    'save_and_continue'         :   'Save and Continue',
    'publish_and_continue'      :   'Publish and Continue',
    'save_and_preview'          :   'Save and Preview',
    'save_and_done'             :   'Save and Done',
    'cancel'                    :   'Cancel',
    'edit_title'                :   'Edit title',
    'remove_media'              :   'Remove media from selection',
    'help_close_btn'            :   'Close help panel',
    'help_restore_btn'          :   'Restore help for this page',
    'cta_new_business_unit'     :   "Create New Business Unit",
    'edit_this_message'         :   "Edit this message",
    'view_next_unread_message'  :   "View next unread message",
    'view_latest_unread_message':   "View latest unread message",
    'mark_message_as_read'      :   "Mark message as read",
    'view_all_messages'         :   "View all messages",
    'upload_new_image'          :   "Upload new image",
    'upload'                    :   "Upload image",
    'select_all'                :   "Select all",
    'deselect_all'              :   "Deselect all",
    'select_all_visible'        :   "Select all visible",
    'deselect_all_visible'      :   "Deselect all visible",
    'archive_selected'          :   "Archive selected",
    'restore_selected'          :   "Restore selected",
    'archive_file'              :   "Archive image",
    'restore_file'              :   "Restore image",
    'view_original_file'        :   "View original",
    'view_archived_images'      :   "View archived images",
    'view_active_images'        :   "View active images",
    'back_previous'             :   "Back to previous",
    'done'                      :   'Done',
    'finish'                    :   'Finish',
    'create_new_user'           :   'Create new user',
    'request_reset_link'        :   "Request password reset link",
    'reset_password'            :   "Reset password",
    'reset_password_link'       :   "Forgotten your password?",
    'download'                  :   "Download",
    'download_csv'              :   "Export all data (csv)",
    'view_all_adverts'          :   "View all adverts",
    'create_new_advert'         :   "Create new advert",
    'save_and_update_preview'   :  "Save changes and update preview",

    // pagination
    'pagination_previous'   :   'Prev',
    'pagination_next'       :   'Next',
    'pagination_overview'   :   'Page %1% of %2%',

    // validate
    'validate_required'     :   "This field is required",
    'validate_string_min'   :   "Too short, requires at least %d% characters",
    'validate_string_max'   :   "Too long, requires at maximum of %d% characters",
    'validate_date_min'     :   "Date must be on or after %d%",
    'validate_date_max'     :   "Date must be on or before %d%",
    'validate_date_compare_after'   :   "Date must be after %s%",
    'validate_email_format' :   "Please enter a valid email address",
    'validate_password_different_characters':   "Your password must contain %d% unique characters",
    'validate_password_types_of_characters' :   "Your password must contain at least 1 upper and lower case character, one number and one symbol",

    // content errors
    'content_404'     :   "This content could not be found",

    // filters
    'showing_all_business_units':   "Showing all departments",

    // Misc
    'asset_owner_identifier'        :   "You",
    'date_today'                    :   "Today",
    'date_yesterday'                :   "Yesterday",
    'message_date'                  :   "Message date",
    'unread_message_panel_title'    :   "New message!",
    'all_business_units'            :   "All Departments",
    'archived'                      :   "Archived",
    'row'                           :   "Row",
    'disabled_label_suffix'         :   "(disabled)",
    'toggle_field_lock'             :   "Toggle field lock",
    'show'                          :   "Show",
    'hide'                          :   "Hide",
    'switch_to_text'                :   "Switch to simple text input",
    'switch_to_text_mobile'         :   "Text",
    'switch_to_options'             :   "Switch to option select",
    'switch_to_options_mobile'      :   "Options",
    'select_media_group'            :   "Select library",
    'selected'                      :   "Selected",
    'select_option'                 :   "Select option",
    'select_different_image'        :   "Select a different image",
    'select_image'                  :   "Select image",
    'cancel_image'                  :   "Reset",
    'status'                        :   "Status",
    'complete'                      :   "Complete",
    'incomplete'                    :   "Incomplete",
    'jump_to'                       :   "Jump to",
    'role_user'                     :   "User",
    'role_admin'                    :   "Admin",

    'unsaved_navigation_confirm'    :   "Do you really want to leave? you have unsaved changes!",

    'no_crop'           :   'No crop',
    'free_crop'         :   'Free',
    'flip_x'            :   'Flip image',
    'preview_image'     :   'Preview image',
    'no_text_options'   :   'No text styles available for this format',
    'enter_text'        :   'Enter your text',
    'enable_manual_text_position'   : 'Enable manual text positioning',
    'yes'               :   'Yes',
    'no'                :   'No',
    'horizontal'        :   'X%',
    'vertical'          :   'Y%'
};

export default textStrings;