<template>

  <div>
    <Header/>

    <SystemMessage/>

    <main class="root-element">
      <Breadcrumbs/>
      <PageTitle/>

      <div class="root-element">
        <div class="login-outer">
          <div class="login-inner">
            <h1 class="login-title">{{ text.login }}</h1>

            <div class="panel panel-primary">
              <div class="panel-heading">
                <div class="panel-heading-text">{{ text.login }}</div>
              </div>
              <div class="panel-content">
                <form class="form">
                  <Form v-model="loginForm" ref="form"/>
                  <input type="submit" :title="text.login" class="btn btn-primary btn-noyspace" @click="login($event)"
                         @keyup.enter="login($event)" :value="text.login">
                  <router-link :to="{ name: 'PasswordReset' }" :title="text.reset_password_link"
                               class="btn btn-frameless btn-noyspace">{{ text.reset_password_link }}
                  </router-link>
                </form>
              </div>
            </div>

            <Help/>
          </div>
        </div>
      </div>
    </main>

    <Footer/>
  </div>

</template>

<script>
import Auth from '@/shared/authentication';
import {MESSAGE_TYPE_SUCCESS, MESSAGE_TYPE_ERROR} from "@/shared/consts";

import {log} from "@/shared/utils";
import {formWrapperMixin} from "@/views/mixins/formwrapper";
import lang from "@/shared/lang";
import store from "@/store"

import Form from "@/components/form/form";
import Help from "@/components/help";
import Breadcrumbs from "@/components/breadcrumbs";
import PageTitle from "@/components/pagetitle";
import Header from '@/components/header';
import Footer from '@/components/footer';
import SystemMessage from '@/components/systemmessage';


export default {
  name: 'Login',
  components: {Breadcrumbs, PageTitle, Help, Header, Footer, SystemMessage, Form},
  mixins: [formWrapperMixin],
  data() {
    return {
      loginForm: [
        {
          id: 1,
          type: 'text',
          name: 'username',
          label: 'Email',
          placeholder: "",
          tooltip: "",
          value: "",
          validate: {
            type: 'email',
            required: true
          }
          // OPTIONAL EXTRAS
          // disabled : true,
          // readonly : true,
          // errors : []
        },
        {
          id: 2,
          type: 'password',
          name: 'password',
          label: 'Password',
          placeholder: "Password placeholder",
          tooltip: "",
          value: "",
          validate: {
            type: 'string',
            required: true
          }
        },
        /*{
            id: 3,
            type: 'date',
            name: 'date',
            label: 'Date',
            placeholder: "dd/mm/yyyy",
            tooltip: "",
            value : ""
        },
        {
            id: 4,
            type: 'textarea',
            name: 'comment',
            label: 'Have your say',
            placeholder: "",
            tooltip: "",
            value : ""
            //textarea can also specify number of rows
        },
        {
            id: 5,
            type: 'select',
            name: 'cars',
            label: 'Choose one',
            placeholder: "",
            tooltip: "",
            value : "",
            options : [
                {"value" : "volvo", "text" : "Volvo"},
                {"value" : "ford", "text" : "Ford"},
                {"value" : "volkswagen", "text" : "Volkswagen"}
            ],
        },
        {
            id: 6,
            type: 'checkbox',
            name: 'checkbox',
            label: 'Tick me',
            text: 'Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great! Tick this if you are great!Tick this if you are great! Tick this if you are great! Tick this if you are great!',
            placeholder: "",
            tooltip: "Tooltip here",
            value : "",
            options : [
                {"value" : "volvo", "text" : "Volvo"},
                {"value" : "ford", "text" : "Ford"},
                {"value" : "volkswagen", "text" : "Volkswagen"}
            ],
        },
        {
            id: 7,
            type: 'radio',
            name: 'radio',
            label: 'Tick me if you are...',
            placeholder: "",
            tooltip: "",
            value : "",
            options : [
                {"value" : "great", "text" : "I am great"},
                {"value" : "brilliant", "text" : "I am brilliant"},
                {"value" : "awesome", "text" : "I am awesome"}
            ],
        },
        {
            id: 8,
            type: 'file',
            name: 'files',
            label: 'Chose file',
            placeholder: "",
            tooltip: "",
            value : []
        },*/
      ]
    }
  },
  computed: {
    text() {
      return lang;
    }
  },
  methods: {
    async login(e) {
      e.preventDefault();

      if (this.$refs.form.save()) {
        let userCredentials = {
          username: this.getFormDataValueByName(this.loginForm, "username"),
          password: this.getFormDataValueByName(this.loginForm, "password")
        };

        log(["trigger login!", userCredentials]);

        let success = await Auth.login(userCredentials);

        if (success !== false) {
          // Attempt to send user to their redirect url
          if (this.$route.query.returnUrl && this.$router.resolve(this.$route.query.returnUrl).route.name !== "404") {
            this.$router.push({name: this.$router.resolve(this.$route.query.returnUrl).route.name})
          }
          // Redirect user to relevant home page
          else if (Auth.isAdmin()) {
            this.$router.push({name: 'AdminHome'});
          } else {
            this.$router.push({name: 'UserHome'});
          }
        }
      }

    },
  },
  created() {
    log(["Login page resetting auth credentials!"]);
    Auth.resetAuth();

    if (this.$route.params.action === "hasloggedout") {
      store.dispatch('addSystemMessageAction', {
        'type': MESSAGE_TYPE_SUCCESS,
        'message': this.text.message_logout
      });
    } else if (this.$route.params.action === "nopermission") {
      store.dispatch('addSystemMessageAction', {
        'type': MESSAGE_TYPE_ERROR,
        'message': this.text.message_no_permission
      });
    }


  }
}
</script>

<style lang="scss" scoped>

.login-outer {
  @apply grid grid-cols-12 gap-4;

  .login-inner {

    @apply col-span-12;

    @screen sm {
      @apply col-span-10 col-start-2;
    }
    @screen md {
      @apply col-span-8 col-start-3;
    }
    @screen lg {
      @apply col-span-6 col-start-4;
    }
  }
}

</style>