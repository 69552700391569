<template>
    <div class="form-input form-file" :class="[classString, {'error': error}, {'locked': element.locked === true, 'unlocked' : element.locked === false}]">
        <input autocomplete="off" type="file" :id="element.name" :disabled="element.disabled" :readonly="element.readonly"  @change="onFileSelected($event);" />
        <label :for="element.name"><span class="label"><Label :label="element.label" :tooltip="element.tooltip" /></span> <span class="btn btn-secondary btn-small">Click to select</span> <span class="form-file-selected-name">No file selected</span></label>
        <span class="error-message" v-if="error">{{error}}</span>
    </div>
</template>

<script>
    import {log} from "@/shared/utils";

    import Label from "@/components/form/label";
    import {formElementMixin} from "@/components/mixins/formelement";

    export default {
        name: "Formelement_file",
        components: {Label},
        mixins: [formElementMixin],
        data() {
            return {
                fileNameElement: "",
                labelVal: ""
            }
        },
        props: {
            element: {
                type: Object
            },
            value: {
                type: [File, Array],
                default: () => []
            },
            classString: {
                type: String
            }
        },
        methods: {
            onFileSelected(e) {
                let fileName = ( e.target.files && e.target.files.length > 0 ) ? e.target.value.split( '\\' ).pop() : null;
                this.inputValue = [...e.target.files][0];

                // eslint-disable-next-line no-console
                console.log(["file selected!", fileName, this.labelVal]);

                this.fileNameElement.innerHTML = fileName ? fileName : this.labelVal;
            },
            initFileInput() {
                let input = document.querySelector('#' + this.element.name);
                let label = input.nextElementSibling;
                this.fileNameElement = label.querySelector(".form-file-selected-name");
                this.labelVal = this.fileNameElement.innerHTML;
            },
            clear() {
                document.getElementById(this.element.name).value = [];
                this.fileNameElement.innerHTML = this.labelVal;
            },
        },
        computed: {
            inputValue: {
                get() {
                    log("GETTING FILE SELECT VALUE");
                    return this.value
                },
                set(inputValue) {this.$emit('input', inputValue)}
            }
        },
        mounted() {
            this.initFileInput();
        }
    }
</script>