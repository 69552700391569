<template>

    <div>
        <Header />

        <SystemMessage />

        <main class="root-element">
            <Breadcrumbs />
            <PageTitle />

            <div class="root-element body-content">
                <h1>{{text.title_maintenance}}</h1>
                <p v-html="text.body_maintenance"></p>
            </div>
        </main>

        <Footer />
    </div>

</template>

<script>
    import lang from "@/shared/lang";
    import Breadcrumbs from "@/components/breadcrumbs";
    import PageTitle from "@/components/pagetitle";
    import Header from '@/components/header';
    import Footer from '@/components/footer';
    import SystemMessage from '@/components/systemmessage';

    export default {
        name: 'Maintenance',
        components: {Breadcrumbs, PageTitle, Header, Footer, SystemMessage},
        computed: {
            text() {
                return lang;
            }
        },
    }
</script>

<style lang="scss" scoped>


</style>
